import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PhoneInput } from 'common/components/design-system/molecules/phoneInput'
import { InputField } from '../../../../../design-system/molecules/inputField'
import { EmailInput } from 'common/components/design-system/molecules/EmailInput'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import { Tooltip } from '../../../Tooltip'
import React, { useEffect, useState } from 'react'
import { theme } from '../../../../theme'
import { CalculatorStepCompound as CalculatorStepCompoundInterface } from '../../interfaces'

export interface CalculatorStepCompoundProps extends CalculatorStepCompoundInterface {
    updateData: (newData: { [key: string]: any }) => void
}


export const CalculatorStepCompound: React.FC<CalculatorStepCompoundProps> = ({
    data = {},
    fieldName,
    conditional_component,
    contingent_components,
    test_condition,
    group,
    title,
    tooltip,
    updateData
}) => {

    const [buttonState, setState] = useState<string | undefined>(
        data[test_condition]
    )

    const updateContingentComponent = (value: any, componentFieldName: string) => {
        updateData({ [componentFieldName as string]: value })
        update(test_condition)
    }

    const affirmTestCondition = (value: any) => {
        updateData({ [test_condition]: value })
    }

    const update = (value: string | number) => {
        updateData({ [fieldName]: value })
    }

    useEffect(() => {
        contingent_components.forEach((component: any) => {
            let name = component.calculatordata__field_name
            updateData({ [name]: '' })
        })
        updateData({ [test_condition]: 'N' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { palette } = theme

    const useStyles = makeStyles({
        root: {
            display: 'flex'
        },
        button: {
            lineHeight: '16px',
            borderRadius: '4px',
            border: '0px solid !important',
            backgroundColor: palette.NEUTRAL_50,
            fontWeight: 700,
            fontFamily: 'Assistant',
            fontSize: '14px',
            width: '124px',
            height: '52px',
            margin: '8px',
            boxShadow: '0px',
            color: palette.BRAND_900,
            '&:hover': {
                backgroundColor: palette.ACCENT_500_PRIMARY,
                color: palette.WHITE
            },
            '&:disabled': {
                color: palette.ACCENT_500_PRIMARY,
                backgroundColor: `${palette.NEUTRAL_50} !important`
            }
        },
        selected: {
            backgroundColor: palette.ACCENT_500_PRIMARY,
            color: palette.WHITE
        }
    })
    const classes = useStyles()

return (
    <>
    {conditional_component[0].type === 'calculatorstepbinarychoice' && (
        <>
    {group && (
            <Box>
                {tooltip && (
                    <Tooltip
                        title={tooltip.title}
                        content={tooltip.content}
                        alignRight={true}
                    />
                )}
                {title && <SmallTitle text={title} />}
            </Box>
        )}
        <Box display='flex'>
                <Button
                    color={
                        buttonState === (conditional_component[0].options[0].value || conditional_component[0].options[0].label)
                            ? 'secondary'
                            : undefined
                    }
                    disableElevation
                    fullWidth
                    onClick={() => {
                        affirmTestCondition(conditional_component[0].options[0].value)
                        setState(conditional_component[0].options[0].value)
                        update('')
                    }}
                    size='large'
                    variant={
                        buttonState === (conditional_component[0].options[0].value || conditional_component[0].options[0].label)
                            ? 'contained'
                            : 'outlined'
                    }
                    className={`${classes.button} ${
                        buttonState === (conditional_component[0].options[0].value || conditional_component[0].options[0].label)
                            ? classes.selected
                            : ''
                    }`}
                >
                    {conditional_component[0].options[0].label}
                </Button>

                <Button
                    color={
                        buttonState === (conditional_component[0].options[1].value || conditional_component[0].options[1].label)
                            ? 'secondary'
                            : undefined
                    }
                    disableElevation
                    fullWidth
                    onClick={() => {
                        affirmTestCondition(conditional_component[0].options[1].value)
                        setState(conditional_component[0].options[1].value)
                        update('none')
                    }}
                    size='large'
                    variant={
                        buttonState === (conditional_component[0].options[1].value || conditional_component[0].options[1].label)
                            ? 'contained'
                            : 'outlined'
                    }
                    className={`${classes.button} ${
                        buttonState === (conditional_component[0].options[1].value || conditional_component[0].options[1].label)
                            ? classes.selected
                            : ''
                    }`}
                >
                    {conditional_component[0].options[1].label}
                </Button>
            </Box>
            {buttonState === 'Y' && (
                <Box alignItems='center'>
                    {contingent_components.map(
                        (
                            component: any
                        ) => (
                            <Box alignItems='center' key={component.codename}>

                            {component.type === 'calculatorstepinput' && (
                                <InputField
                                    placeholder={component.placeholder}
                                    onBlur={(inputVal) => {
                                        updateContingentComponent(inputVal, component.calculatordata__field_name)
                                    }} />
                            )}
                            {component.type === 'calculatorstepphonenumber' && (
                                <PhoneInput
                                    placeholder={component.placeholder}
                                    onBlur={(inputVal) => {
                                        updateContingentComponent(inputVal, component.calculatordata__field_name)
                                    }}/>
                            )}
                            {component.type === 'calculatorstepemail' && (
                                <EmailInput
                                    placeholder={component.placeholder}
                                    onBlur={(inputVal) => {
                                        updateContingentComponent(inputVal, component.calculatordata__field_name)
                                    }}/>


                            )}
                            </Box>
                        )
                    )}

                </Box>
            )}
            </>

    )}



    </>
)}
