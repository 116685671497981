import { UMBRELLA_CIRCLE } from 'common/components/design-system/assets/icons'
import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import { createCrmLead } from 'common/services/crm/createCrmLead'
import { MortgageProtectionQuote } from './MortgageProtectionQuote'
import {
    configureCalculatorQuoteData,
    consultationTransformation,
    jointApplicationTransformation,
    maxAgeTransformation,
} from './configFunctions'

export const mortgageProtection: CalculatorConfig = {
    getQuoteData: configureCalculatorQuoteData('mortgage-protection'),
    icon: UMBRELLA_CIRCLE,
    quoteComponent:
        MortgageProtectionQuote as CalculatorConfig['quoteComponent'],
    stepsTransformation: (steps, data) => {
        const firstSteps = jointApplicationTransformation(steps, data)
        const secondSteps = consultationTransformation(firstSteps, data)
        return maxAgeTransformation(secondSteps, data)
    },
    createCrmLead: (data) => createCrmLead(data, 'Mortgage Protection'),
}
