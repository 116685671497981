import {
    Calculator,
    getCalculatorData,
} from 'common/components/templates/Calculator'
import { Box } from '@material-ui/core'
import React from 'react'

export interface CalculatorPageProps {
    pageContext: any
}

export const CalculatorPage: React.FC<CalculatorPageProps> = ({
    pageContext,
}) => {
    return (
        <Box display={{ xs: 'flex', md: 'block', lg: 'block' }}>
            <Calculator {...getCalculatorData(pageContext)} />
        </Box>
    )
}

export default CalculatorPage
