import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Button } from '../../atoms/button'
import { Caption } from '../../atoms/Caption'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardDataProps {
    classes: any
    label?: string
    data?: string | string[]
    type?: string
    avatar?: string
    maxWidth?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        fontFamily: '"Assistant", sans-serif',
        color: palette.BLACK,
        fontStyle: 'normal',
        width: '100%',
        borderStyle: 'none none solid none',
        borderColor: '#EBEDF7',
        borderWidth: '3px',
        paddingBottom: '10px'
    },
    basic: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px'
    },
    copy: {
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    profile: {
        paddingLeft: '44px',
        marginTop: '8px'
    },
    list: {
        padding: '0px',
        margin: '0px'
    },
    listItem: {
        listStyle: 'none',
        padding: '0px',
        margin: '2px'
    },
    listItemTitle: {
        listStyle: 'none',
        color: '#99A2CD',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '0px',
        margin: '2px'
    },
    listItemTerm: {
        listStyle: 'none',
        fontStyle: 'Italic',
        padding: '0px',
        margin: '2px'
    }
}

const TYPES = {
    BASIC: 'basic',
    PROFILE: 'profile',
    COPY: 'copy'
}

export const CardDataElem: React.FC<CardDataProps> = ({ ...props }) => {
    const { classes, label = '', data, type } = props
    const { PROFILE, COPY } = TYPES

    const getClasses = () => {
        switch (type) {
            case COPY:
                return classes.copy
            case PROFILE:
                return `${classes.basic} ${classes.profile}`
            default:
                return classes.basic
        }
    }

    const correctClass = getClasses()

    const copyToClipboard = () => {
        if (!Array.isArray(data)) {
            const el = document.createElement('textarea')
            el.value = data || ''
            document.body.appendChild(el)
            el.select()
            document.execCommand('copy')
            document.body.removeChild(el)
        }
    }

    return (
        <div
            className={classes.root}
            //style={{ maxWidth: Number(maximumWidth) }}
        >
            <Caption text={label} />
            <div className={correctClass}>
                {!Array.isArray(data) && data}
                {Array.isArray(data) && (
                    <ul className={classes.list}>
                        {data.map(item => {
                            if (item.includes('title')) {
                                return (
                                    <li className={classes.listItemTitle}>
                                        {item.replace('title ', '')}
                                    </li>
                                )
                            }

                            return <li className={classes.listItem}>{item}</li>
                        })}
                    </ul>
                )}
                {type === COPY && (
                    <Button
                        type='secondary'
                        label='Copy'
                        onClick={copyToClipboard}
                        size='small'
                    />
                )}
            </div>
            <div className={classes.stripedBorder}></div>
        </div>
    )
}

export const CardData = withStyles(styles)(CardDataElem)
