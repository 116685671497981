import ARROW_DOWN_500 from './assets/arrow-down-small-500-primary.svg'
import ARROW_DOWN_900 from './assets/arrow-down-small-900.svg'
import ARROW_DOWN_200 from './assets/arrow-down-small-monochrome-200.svg'
import ARROW_LEFT_CIRCLE_500 from './assets/arrow-left-circle-500-primary.svg'
import ARROW_LEFT_500_PRIMARY from './assets/arrow-left-500-primary.svg'
import ARROW_LEFT_CIRCLE_WHITE from './assets/arrow-left-circle-white.svg'
import ARROW_RIGHT_CIRCLE_500 from './assets/arrow-right-circle-500-primary.svg'
import ARROW_RIGHT_CIRCLE_WHITE from './assets/arrow-right-circle-white.svg'
import ARROW_SEND_WHITE from './assets/arrow-send-white.svg'
import PLUS_GHOST_ACTIVE from './assets/plus-ghost-active.svg'
import PLUS_GHOST_DISABLED from './assets/plus-ghost-disabled.svg'
import PLUS_GHOST_PRESSED from './assets/plus-ghost-pressed.svg'
import PLUS_WHITE from './assets/plus-white.svg'
import RANGE_WHITE from './assets/range-white.svg'
import TICK_SMALL_500 from './assets/tick-small-500-primary.svg'
import TICK_SMALL_900 from './assets/tick-small-900.svg'
import TICK_SMALL_200 from './assets/tick-small-monochrome-200.svg'

export const icons: { [key: string]: any } = {
    arrowSend: {
        brandPrimary: {
            active: ARROW_SEND_WHITE,
            hover: ARROW_SEND_WHITE,
            pressed: ARROW_SEND_WHITE,
            disabled: ARROW_SEND_WHITE
        }
    },
    rightArrow: {
        brandPrimary: {
            active: ARROW_RIGHT_CIRCLE_WHITE,
            hover: ARROW_RIGHT_CIRCLE_WHITE,
            pressed: ARROW_RIGHT_CIRCLE_WHITE,
            disabled: ARROW_RIGHT_CIRCLE_WHITE
        },
        vibrantPrimary: {
            active: ARROW_RIGHT_CIRCLE_WHITE,
            hover: ARROW_RIGHT_CIRCLE_WHITE,
            pressed: ARROW_RIGHT_CIRCLE_WHITE,
            disabled: ARROW_RIGHT_CIRCLE_WHITE
        },
        secondary: {
            active: ARROW_RIGHT_CIRCLE_500,
            hover: ARROW_RIGHT_CIRCLE_WHITE,
            pressed: ARROW_RIGHT_CIRCLE_WHITE,
            disabled: ARROW_RIGHT_CIRCLE_WHITE
        },
        ghost: {
            active: ARROW_RIGHT_CIRCLE_500,
            hover: ARROW_RIGHT_CIRCLE_500,
            pressed: ARROW_RIGHT_CIRCLE_500,
            disabled: ARROW_RIGHT_CIRCLE_500
        }
    },
    leftArrow: {
        brandPrimary: {
            active: ARROW_LEFT_CIRCLE_WHITE,
            hover: ARROW_LEFT_CIRCLE_WHITE,
            pressed: ARROW_LEFT_CIRCLE_WHITE,
            disabled: ARROW_LEFT_CIRCLE_WHITE
        },
        vibrantPrimary: {
            active: ARROW_LEFT_CIRCLE_WHITE,
            hover: ARROW_LEFT_CIRCLE_WHITE,
            pressed: ARROW_LEFT_CIRCLE_WHITE,
            disabled: ARROW_LEFT_CIRCLE_WHITE
        },
        secondary: {
            active: ARROW_LEFT_CIRCLE_500,
            hover: ARROW_LEFT_CIRCLE_WHITE,
            pressed: ARROW_LEFT_CIRCLE_WHITE,
            disabled: ARROW_LEFT_CIRCLE_WHITE
        },
        buttonIcon: {
            active: ARROW_LEFT_500_PRIMARY,
            hover: ARROW_LEFT_CIRCLE_WHITE,
            pressed: ARROW_LEFT_CIRCLE_WHITE,
            disabled: ARROW_LEFT_CIRCLE_WHITE
        },
        ghost: {
            active: ARROW_LEFT_CIRCLE_500,
            hover: ARROW_LEFT_CIRCLE_500,
            pressed: ARROW_LEFT_CIRCLE_500,
            disabled: ARROW_LEFT_CIRCLE_500
        }
    },
    arrowDown: {
        brandPrimary: {
            active: ARROW_DOWN_500,
            hover: ARROW_DOWN_500,
            pressed: ARROW_DOWN_500,
            disabled: ARROW_DOWN_500
        },
        vibrantPrimary: {
            active: ARROW_DOWN_500,
            hover: ARROW_DOWN_500,
            pressed: ARROW_DOWN_500,
            disabled: ARROW_DOWN_500
        },
        secondary: {
            active: ARROW_DOWN_500,
            hover: ARROW_DOWN_500,
            pressed: ARROW_DOWN_500,
            disabled: ARROW_DOWN_500
        },
        ghost: {
            active: ARROW_DOWN_500,
            hover: ARROW_DOWN_500,
            pressed: ARROW_DOWN_900,
            disabled: ARROW_DOWN_200
        }
    },
    tick: {
        brandPrimary: {
            active: TICK_SMALL_500,
            hover: TICK_SMALL_500,
            pressed: TICK_SMALL_500,
            disabled: TICK_SMALL_500
        },
        vibrantPrimary: {
            active: TICK_SMALL_500,
            hover: TICK_SMALL_500,
            pressed: TICK_SMALL_500,
            disabled: TICK_SMALL_500
        },
        secondary: {
            active: TICK_SMALL_500,
            hover: TICK_SMALL_500,
            pressed: TICK_SMALL_500,
            disabled: TICK_SMALL_500
        },
        ghost: {
            active: TICK_SMALL_500,
            hover: TICK_SMALL_500,
            pressed: TICK_SMALL_900,
            disabled: TICK_SMALL_200
        }
    },
    plus: {
        brandPrimary: {
            active: PLUS_WHITE,
            hover: PLUS_WHITE,
            pressed: PLUS_WHITE,
            disabled: PLUS_WHITE
        },
        vibrantPrimary: {
            active: PLUS_WHITE,
            hover: PLUS_WHITE,
            pressed: PLUS_WHITE,
            disabled: PLUS_WHITE
        },
        secondary: {
            active: PLUS_WHITE,
            hover: PLUS_WHITE,
            pressed: PLUS_WHITE,
            disabled: PLUS_WHITE
        },
        ghost: {
            active: PLUS_GHOST_ACTIVE,
            hover: PLUS_GHOST_ACTIVE,
            pressed: PLUS_GHOST_PRESSED,
            disabled: PLUS_GHOST_DISABLED
        }
    },
    rangeWhite: {
        brandPrimary: {
            active: RANGE_WHITE,
            hover: RANGE_WHITE,
            pressed: RANGE_WHITE,
            disabled: RANGE_WHITE
        },
        vibrantPrimary: {
            active: RANGE_WHITE,
            hover: RANGE_WHITE,
            pressed: RANGE_WHITE,
            disabled: RANGE_WHITE
        },
        secondary: {
            active: RANGE_WHITE,
            hover: RANGE_WHITE,
            pressed: RANGE_WHITE,
            disabled: RANGE_WHITE
        },
        ghost: {
            active: RANGE_WHITE,
            hover: RANGE_WHITE,
            pressed: RANGE_WHITE,
            disabled: RANGE_WHITE
        }
    }
}
