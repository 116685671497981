import React, { useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { Calculator as CalculatorProps } from './interfaces'
import { stepGrouping } from './stepGrouping'
import { CalculatorStep } from './steps/CalculatorStep'
import { CalculatorStepCommon } from './steps/CalculatorStepCommon/CalculatorStepCommon'
import { useDidMountEffect } from './useDidMountEffect'

export const CalculatorCms: React.FC<CalculatorProps> = ({
    buttons = {},
    config,
    steps,
    quote,
    email,
    toolLabel,
    toolCaption,
    CalculatorType,
}) => {
    const desktop = isDesktop
    const [step, setStep] = useState<number>(0)
    const [data, setData] = useState<any>(config.initialData || {})

    const [quoteData, setQuoteData] = useState<any>({})
    const nextStep = () => {
        setStep((currentStep) => currentStep + 1)
    }
    const previousStep = () => {
        setStep((currentStep) => currentStep - 1)
    }
    const updateData = (newData: { [key: string]: any }) =>
        setData((oldData: any) => ({ ...oldData, ...newData }))

    const stepsWithGrouping = stepGrouping(steps, desktop)

    const transformedSteps = config.stepsTransformation
        ? config.stepsTransformation(stepsWithGrouping, data)
        : stepsWithGrouping

    const isLastStep = step === transformedSteps.length

    useDidMountEffect(async () => {
        if (isLastStep) {
            const quoteData = await config.getQuoteData(data, CalculatorType)
            config.createCrmLead?.({ ...data, quoteData }, CalculatorType)
            setQuoteData(quoteData)
        }
    }, [isLastStep])

    return (
        <CalculatorStepCommon
            data={data}
            {...transformedSteps[step]}
            icon={config.icon}
            length={transformedSteps.length + 1}
            nextStep={step < transformedSteps.length ? nextStep : undefined}
            noCard={step === transformedSteps.length}
            onStepChange={config.onStepChange}
            previousStep={step > 0 ? previousStep : undefined}
            reset={
                buttons.reset
                    ? () => {
                          setData(config.initialData || {})
                          setStep(0)
                      }
                    : undefined
            }
            step={step}
            steps={transformedSteps}
            label={toolLabel as string}
            caption={toolCaption as string}
            calculatorType={CalculatorType as string}
        >
            {step === transformedSteps.length ? (
                <config.quoteComponent
                    data={{ input: data, api: quoteData, cms: quote }}
                />
            ) : (
                <CalculatorStep
                    {...transformedSteps[step]}
                    data={data}
                    key={step}
                    updateData={updateData}
                />
            )}
        </CalculatorStepCommon>
    )
}
