import { Box } from '@material-ui/core'
import React from 'react'
import { CalculatorStepGroup as CalculatorStepGroupProps } from '../../interfaces'
import { CalculatorStep } from '../CalculatorStep/CalculatorStep'

export const CalculatorStepGroup: React.FC<CalculatorStepGroupProps> = ({
    steps,
    updateData,
}) => (
    <>
        {steps.map((step, index) => {
            return (
                <Box key={step.codename} mt={index ? 10 : 0}>
                    <CalculatorStep {...step} updateData={updateData} group />
                </Box>
            )
        })}
    </>
)
