import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import { Input } from '../../atoms/input'
import { Label } from '../../atoms/label'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputFieldProps {
    classes: any
    placeholder?: string
    value?: string | number
    onchange?: (val: string | number) => void
    label?: string
    isGreyBackground?: boolean
    isOptional?: boolean
    isDisabled?: boolean
    isValid?: boolean
    helper?: string
    type?: string
    background?: string
    onBlur?: (val: string | number) => void
    formattedPhone?: string
    onKeyDown?: (val: string | number) => void
    name?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    grey: {
        backgroundColor: 'rgb(245, 244, 247)',
        width: '328px',
        padding: '16px'
    },
    optional: {
        fontSize: '14px',
        letterSpacing: '0.32px',
        lineHeight: '20px',
        fontFamily: 'Assistant',
        color: palette.NEUTRAL_700,
        fontStyle: 'italic',
        fontWeight: 400,
        paddingBottom: '4px'
    }
}

export const Field: React.FC<InputFieldProps> = ({ ...props }) => {
    const {
        classes,
        placeholder,
        value,
        onchange,
        label,
        isGreyBackground,
        isOptional,
        isDisabled,
        isValid,
        helper,
        type,
        background,
        onBlur,
        formattedPhone,
        onKeyDown,
        name
    } = props
    const [inputValue, setValue] = useState(value)
    const inputType = type === undefined ? 'text' : type

    const handleChange = (val: any) => {
        setValue(val)
        onchange && onchange(val)
    }
    return (
        <div className={`${classes.root} ${isGreyBackground && classes.grey}`}>
            <div>
                {label && <Label value={label} isDisabled={isDisabled} />}
                {isOptional && label !== '' && label !== undefined && (
                    <span className={classes.optional}> - Optional</span>
                )}
            </div>
            <Input
                name={name}
                onKeyDown={onKeyDown}
                formattedPhone={formattedPhone}
                onBlur={onBlur}
                background={background}
                label={label}
                type={inputType}
                isWhiteBackground={isGreyBackground}
                placeholder={placeholder}
                value={inputValue}
                onchange={handleChange}
                isDisabled={isDisabled}
                isValid={isValid}
                helper={helper}
            />
        </div>
    )
}

export const InputField = withStyles(styles)(Field)
