import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'common/components/design-system/theme'
import React from 'react'
import { CalculatorStepList as CalculatorStepListProps } from '../../interfaces'
import { Text } from 'common/components/molecules/Text'
import { StepperDots } from 'common/components/design-system/atoms/StepperDots'

export const CalculatorStepList: React.FC<CalculatorStepListProps> = ({
    benefits = [],
    listIcon,
    stepper
}) => {
    const { palette } = theme
    const useStyles = makeStyles({
        content: {
            fontWeight: 500,
            fontFamily: 'Assistant',
            fontSize: '18px',
            lineHeight: '28px',
            color: palette.NEUTRAL_700,
            marginTop: '-18px'
        },
        title: {
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '31.2px'
        }
    })
    const classes = useStyles()
    return (
        <>
            {benefits.map(({ listTitle, text, type }) => (
                <Grid container spacing={3}>
                    <Grid item xs={1}>
                        <img
                            src={listIcon}
                            alt='icon'
                            style={{
                                marginRight: 24
                            }}
                        />
                    </Grid>
                    {stepper && <StepperDots total={stepper} current={0} />}
                    <Grid item xs={11}>
                        {listTitle && (
                            <Text
                                className={classes.title}
                                html={true}
                                label={listTitle}
                            />
                        )}

                        <Text
                            className={classes.content}
                            html={true}
                            label={text}
                        />
                    </Grid>
                </Grid>
            ))}
        </>
    )
}
