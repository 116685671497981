import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import { Input } from '../../atoms/input'
import { Label } from '../../atoms/label'
import { Slider } from '../../atoms/slider'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputSliderProps {
    label?: string
    isDisabled?: boolean
    value?: number
    min: number
    max: number
    step: number
    classes: any
    onChange: (value: any) => void
}

const styles: Styles<Theme, {}, string> = {
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    input: { minWidth: 100 },
    inputs: {
        display: 'flex',
        alignItems: 'center',
        '& > span': {
            marginRight: '40px',
            marginTop: '-10px'
        }
    }
}

export const SliderElem: React.FC<InputSliderProps> = ({ ...props }) => {
    const {
        label = '',
        isDisabled,
        value,
        max,
        min,
        step,
        classes,
        onChange
    } = props
    const [sliderValue, setSliderValue] = useState(value)
    const handleChange = (val: any) => {
        onChange && onChange(val)
        setSliderValue(val)
    }

    return (
        <div className={classes.root}>
            <Label value={label} isDisabled={isDisabled} />
            <div className={classes.inputs}>
                <Slider
                    type='text'
                    showThumb={true}
                    value={sliderValue}
                    isDisabled={isDisabled}
                    onChange={handleChange}
                    max={max}
                    min={min}
                    step={step}
                />
                <Input
                    classes={classes.input}
                    label={label}
                    type='number'
                    max={max}
                    min={min}
                    short={true}
                    value={sliderValue}
                    isDisabled={isDisabled}
                    onchange={handleChange}
                />
            </div>
        </div>
    )
}

export const InputSlider = withStyles(styles)(SliderElem)
