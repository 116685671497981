import { Box, BoxProps} from '@material-ui/core'
import { NumberInput } from 'common/components/design-system/molecules/NumberInput'
import React from 'react'

export interface TextboxProps {
    box?: BoxProps
    label: number
    handleToggle: any
} 

export const Textbox: React.FC<TextboxProps> = ({ box, label,  handleToggle}: TextboxProps) => {
    return (
        <Box data-testid='Textbox' {...box}>
            <NumberInput
                onchange={value => handleToggle(value as number)}
                placeholder={label.toString()}
            />
        </Box>
    )
}
