import { CalculatorStepWithData } from 'common/components/design-system/organisms/CalculatorCms/'
import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import TagManager from 'react-gtm-module'

export const pensionsAnalyticsStepChange: CalculatorConfig['onStepChange'] = (
    step,
    steps,
    data
) => {
    const isFirstStep = step === 0
    const isLastStep = step + 1 === steps.length
    const nextStep = steps[step + 1] as CalculatorStepWithData
    const { pathname } = window?.location

    if (isFirstStep) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/disclaimer`
            }
        })
    }
    if (
        !isLastStep
            ? nextStep.fieldName === 'personal-details-desktop'
            : undefined
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/personal-details-desktop`
            }
        })
    }
    if (!isLastStep ? nextStep.fieldName === 'age1' : undefined) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/personal-details-mobile`
            }
        })
    }
    if (
        !isLastStep
            ? nextStep.fieldName === 'current-pension-yes-desktop'
            : undefined
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-pension-yes-desktop`
            }
        })
    }
    if (!isLastStep ? nextStep.fieldName === 'currentPensionVal' : undefined) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-pension-yes-mobile`
            }
        })
    }
    if (
        !isLastStep
            ? nextStep.fieldName === 'current-pension-no-desktop'
            : undefined
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-pension-no-desktop`
            }
        })
    }
    if (!isLastStep ? nextStep.fieldName === 'contributionStart' : undefined) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-pension-no-mobile`
            }
        })
    }
    if (!isLastStep ? nextStep.fieldName === 'target-income' : undefined) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/target-income-desktop`
            }
        })
    }
    if (
        !isLastStep
            ? nextStep.fieldName === 'yearlyRetirementIncome'
            : undefined
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/target-income-mobile`
            }
        })
    }

    if (isLastStep) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/quote-results`
            }
        })
    }
}
