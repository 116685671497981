import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
import PRODUCT_HEALTH from './assets/icon-health.svg'
import PRODUCT_INVESTMENT from './assets/icon-investment.svg'
import PRODUCT_LIFEINSURANCE from './assets/icon-lifeinsurance.svg'
import PRODUCT_PENSION from './assets/icon-pension.svg'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardHeaderProps {
    classes: any
    title?: string
    label?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        fontFamily: '"Assistant", sans-serif',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: '80%'
    },
    title: {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        margin: '0px',
        color: palette.NEUTRAL_900
    },
    logo: {
        width: '54px',
        marginRight: '16px'
    }
}

const getIcon = (title?: string) => {
    switch (title?.toLowerCase()) {
        case 'health insurance':
            return PRODUCT_HEALTH
        case 'life insurance':
            return PRODUCT_LIFEINSURANCE
        case 'pensions':
            return PRODUCT_PENSION
        case 'investments':
            return PRODUCT_INVESTMENT
        default:
            return null
    }
}

export const CardHeaderElem: React.FC<CardHeaderProps> = ({ ...props }) => {
    const { classes, title } = props
    const headerImage = Array.isArray(title)? getIcon(title[0]) : getIcon(title);

    return (
        <div className={classes.root}>
            {headerImage && (
                <img
                    className={classes.logo}
                    src={headerImage}
                    alt='Irish Life'
                />
            )}
            {!Array.isArray(title) && 
                <div>
                    <h2 className={classes.title}>{title}</h2>
                </div>}
            {Array.isArray(title) && (
                title.map(item => {
                    return <div><h2 className={classes.title}>{item}</h2></div>
                })
            )}
        </div>
    )
}

export const CardHeader = withStyles(styles)(CardHeaderElem)
