import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'common/components/design-system/theme'
import React from 'react'
import { CalculatorStepChart as CalculatorStepChartProps } from '../../interfaces'
// import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts'
import { Text } from 'common/components/molecules/Text'
import { Grid } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'

export const CalculatorStepChart: React.FC<CalculatorStepChartProps> = ({
    chartText,
    coverTitle,
    needsTitle,
    title
}) => {
    const { palette } = theme
    const useStyles = makeStyles({
        title: {
            fontWeight: 'normal',
            fontSize: '20px',
            lineHeight: '120%',
            color: palette.BRAND_400,
            margin: '7px 0px'
        },
        totalCover: {
            color: palette.NEUTRAL_900
        },
        stateBenefit: {
            color: palette.VIBRANT_500_PRIMARY
        },
        assumeMortgage: {
            color: palette.BRAND_600_PRIMARY
        },
        gap: {
            color: palette.ACCENT_500_PRIMARY
        }
    })
    // const data = [
    //     {
    //         name: 'Monthly amount',
    //         gap: 2420,
    //         covered: 1220,
    //         other: 1200
    //     }
    // ]
    const classes = useStyles()
    return (
        <>
            {/* <div
                style={{
                    background: '#EEF0FC',
                    width: '300px',
                    height: '215px'
                }}
            >
                <BarChart
                    width={300}
                    height={215}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='name' />
                    <YAxis tickCount={6} />
                    <Legend />
                    <Bar
                        barSize={60}
                        dataKey='covered'
                        stackId='a'
                        fill='#5261AC'
                    />
                    <Bar
                        barSize={60}
                        dataKey='other'
                        stackId='a'
                        fill='#717FE2'
                    />
                    <Bar
                        barSize={60}
                        dataKey='gap'
                        stackId='a'
                        fill='#50C9B5'
                    />
                </BarChart>
            </div> */}
            <Spacer variant='xs' />

            <Grid item xs={12}>
                <Text className={classes.title} label={coverTitle} />
                <span className={classes.totalCover}>Total covered €2,420</span>
                <br></br>
                <span className={classes.stateBenefit}>
                    State Benefit €1,200
                </span>
                <br></br>
                <span className={classes.assumeMortgage}>
                    Asssume mortgage paid off €1,200
                </span>
            </Grid>
            <Spacer variant='xs' />
            <Grid item xs={12}>
                <Text className={classes.title} label={needsTitle} />

                <span className={classes.gap}>Gap €1,480</span>
            </Grid>
            <Grid item xs={12}>
                <Spacer variant='xs' />
                <Text label={chartText} html={true} />
            </Grid>
        </>
    )
}
