import { CALCULATOR_PENSION_ICON } from 'common/components/design-system/assets/icons'
import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import {
    configureCalculatorQuoteData,
    currentPensionTransformation,
    employeeSalaryContributionTransformation,
    employerSalaryContributionTransformation,
    targetContributionTransformation,
    corporatePensionTransformation
} from './configFunctions'
import { PensionsQuote } from './pensionsQuote'

export const pensionsTool: CalculatorConfig = {
    getQuoteData: configureCalculatorQuoteData('pensions'),

    icon: CALCULATOR_PENSION_ICON,
    quoteComponent: PensionsQuote as CalculatorConfig['quoteComponent'],
    stepsTransformation: (steps, data) => {
        const firstSteps = currentPensionTransformation(steps, data)
        const secondSteps = employeeSalaryContributionTransformation(
            firstSteps,
            data
        )
        const thirdSteps = employerSalaryContributionTransformation(
            secondSteps,
            data
        )
        const fourthSteps = corporatePensionTransformation(thirdSteps, data)
        return targetContributionTransformation(fourthSteps, data)
    }
}
