import { Box } from '@material-ui/core'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import React, { useEffect } from 'react'
import { InputSlider } from '../../../../molecules/inputSlider'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepSlider as CalculatorStepSliderInterface } from '../../interfaces'

export interface CalculatorStepSliderProps
    extends CalculatorStepSliderInterface {
    updateData: (newData: { [key: string]: any }) => void
}
//updateData function that puts a new value into the calculator
//when components mounts, it uses useEffect to default to min
//add title prop to sliders
export const CalculatorStepSlider: React.FC<CalculatorStepSliderProps> = ({
    caption = '',
    data = {},
    fieldName,
    group,
    label,
    max,
    min,
    title,
    tooltip,
    step = 1,
    updateData
}) => {
    useEffect(() => {
        updateData({ [fieldName]: min })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const sliderValue = data[fieldName] || min
    const update = (value: number) => {
        updateData({ [fieldName]: value })
    }
    return (
        <>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}
            <Box alignItems='center'>
                <InputSlider
                    data-testid='InputSlider'
                    max={max}
                    min={min}
                    onChange={value => update(value as number)}
                    step={step}
                    value={sliderValue}
                />
            </Box>
        </>
    )
}
