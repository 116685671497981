import { Box } from '@material-ui/core'
import {
    CalculatorCms,
    CalculatorSteps,
} from 'common/components/design-system/organisms/CalculatorCms'
import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'
import { config } from './config'
import {
    analyticsStepChange,
    consultationStepChange,
} from './config/configFunctions'
import { pensionsAnalyticsStepChange } from './config/pensionsTracking'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CalculatorProps {
    layout: LayoutProps
    steps: CalculatorSteps
    quote: any
    email: { from: string; html: string; subject: string }
    type: string
    toolLabel: string
    toolCaption: string
}

export const Calculator: React.FC<CalculatorProps> = ({
    layout,
    steps,
    quote,
    email,
    type,
    toolLabel,
    toolCaption,
}) => {
    const getCalculatorType = (type: string) => {
        switch (type) {
            case 'Mortgage Protection': {
                return (
                    <CalculatorCms
                        config={{
                            ...config['mortgage-protection'],
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                        }}
                        steps={steps}
                        quote={quote}
                        toolLabel={toolLabel}
                        toolCaption={toolCaption}
                        email={email}
                        CalculatorType={type}
                    />
                )
            }
            case 'Pensions': {
                return (
                    <CalculatorCms
                        steps={steps}
                        config={{
                            ...config['pensions'],
                            onStepChange: (step, steps, data) => {
                                pensionsAnalyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                        }}
                        toolLabel={toolLabel}
                        toolCaption={toolCaption}
                        email={email}
                        quote={quote}
                    />
                )
            }
            case 'Life Long Protection': {
                return (
                    <CalculatorCms
                        steps={steps}
                        config={{
                            ...config['life-long-protection'],
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                        }}
                        email={email}
                        quote={quote}
                        toolCaption={toolCaption}
                        toolLabel={toolLabel}
                    />
                )
            }
            case 'One Plan': {
                return (
                    <CalculatorCms
                        steps={steps}
                        quote={quote}
                        config={{
                            ...config['one-plan'],
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                        }}
                        toolCaption={toolCaption}
                        email={email}
                        toolLabel={toolLabel}
                        CalculatorType={type}
                    />
                )
            }
            case 'Term Life Protection': {
                return (
                    <CalculatorCms
                        steps={steps}
                        config={{
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                            ...config['term-life-protection'],
                        }}
                        toolLabel={toolLabel}
                        quote={quote}
                        email={email}
                        toolCaption={toolCaption}
                    />
                )
            }
            case 'Term Life Copy': {
                return (
                    <CalculatorCms
                        steps={steps}
                        config={{
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                            ...config['term-life-copy'],
                        }}
                        quote={quote}
                        email={email}
                        toolLabel={toolLabel}
                        toolCaption={toolCaption}
                    />
                )
            }
            case 'pensions': {
                return (
                    <CalculatorCms
                        email={email}
                        quote={quote}
                        steps={steps}
                        config={{
                            onStepChange: (step, steps, data) => {
                                analyticsStepChange?.(step, steps, data)
                                consultationStepChange?.(
                                    step,
                                    steps,
                                    data,
                                    type
                                )
                            },
                            ...config['pensions'],
                        }}
                    />
                )
            }
            default:
                break
        }
    }
    return (
        <Layout
            {...{
                ...layout,
                footer: {
                    ...layout.footer,
                },
            }}
            noBreadcrumbs
        >
            <Wrapper
                style={{
                    background:
                        'linear-gradient(8deg, rgb(247, 248, 251) 52%, rgb(232, 233, 244) 0%)',
                }}
            >
                <Box display='flex' flexDirection='column'>
                    <Spacer variant='lg' />
                    <Box display='flex' justifyContent='center' width='100%'>
                        <Box flexGrow={1} maxWidth={'95%'}>
                            {getCalculatorType(type)}
                        </Box>
                    </Box>
                </Box>
                <Spacer variant='sm' />
            </Wrapper>
        </Layout>
    )
}
