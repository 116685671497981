import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useState } from 'react'
import { InputField } from '../inputField'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NumberInputProps {
    classes: any
    label?: string
    isValid?: boolean
    helper?: string
    isOptional?: boolean
    isDisabled?: boolean
    placeholder?: string
    value?: string
    background?: string
    onBlur?: (val: string | number) => void
    onchange?: (val: string | number) => void
    type?: string
}

const styles: Styles<Theme, {}, string> = {}

export const NumberInputField: React.FC<NumberInputProps> = ({ ...props }) => {
    const {
        label,
        isValid,
        helper,
        onchange,
        isOptional,
        isDisabled,
        value,
        placeholder,
        background,
        onBlur,
        type
    } = props

    const [inputValue, setValue] = useState(value)
    const inputType = type === undefined ? 'number' : type

    const handleChange = (val: any) => {
        setValue(val)
        onchange && onchange(val)
    }
    return (
        <InputField
            onBlur={onBlur}
            isOptional={isOptional}
            isDisabled={isDisabled}
            helper={helper}
            label={label}
            type={inputType}
            value={inputValue}
            placeholder={placeholder}
            isValid={isValid}
            background={background}
            onchange={handleChange}
        />
    )
}

export const NumberInput = withStyles(styles)(NumberInputField)
