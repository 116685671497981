import { CalculatorSteps } from 'common/components/design-system/organisms/CalculatorCms/interfaces'
import { LayoutProps } from 'common/components/organisms/Layout'
import { getLayoutData } from 'common/utils/getLayoutData'

export const getCalculatorData = (
    data: any
): {
    layout: LayoutProps
    steps: CalculatorSteps
    quote: any
    email: { from: string; html: string; subject: string }
    type: string
    toolLabel: string
    toolCaption: string
} => {
    return {
        layout: {
            ...getLayoutData(data),
            footer: {
                ...getLayoutData(data).footer,
                regLine: data?.footer__regulatory_text?.[0].text,
            },
        },
        steps: [
            ...data.steps.map((step: any) => {
                const mapStep = (step: any) => {
                    return {
                        ...step,
                        caption: step.calculatorstepheader__caption_3880dc4,
                        fieldName: step.calculatordata__field_name,
                        label: step.calculatorstepheader__label,
                        title: step.calculatorstepheader__title,
                        text: step.calculatorstepheader__regulation_text,
                        tooltip: step.tooltip_snippet__tooltip?.[0],
                        toolType: step.calculatorstepheader__tool_type,
                        variant: step.variant,
                        listIcon: step.icon,
                        listTitle: step.title,
                        contentHeading: step.content_heading,
                        coverTitle: step.cover_title,
                        needsTitle: step.needs_title,
                        chartText: step.chart_text,
                        inputType: step.input_type,
                        cardCaption: step.calculatorstepheader__card_caption,
                        fieldLabel: step.label,
                        fieldHelperText: step.helper_text,
                        fieldOptional: step.optional === 'Yes',
                    }
                }
                if (
                    step.type !== 'calculatorstepgroup' &&
                    step.type !== 'calculatormobilestepgroup'
                )
                    return mapStep(step)
                else
                    return {
                        ...step,
                        toolType: step.tooltype,
                        label: step.calculatorstepheader__label,
                        cardCaption: step.card_caption,
                        steps: step.steps.map(mapStep),
                        fieldName: step.virtual_url,
                    }
            }),
        ],
        quote: {
            refLabel: data.quote_component[0].calculatorstepheader__caption,
            quoteLabel: data.quote_component[0].calculatorstepheader__label,
            cardTwoTopLabel:
                data.quote_component[0]
                    .calculatorstepheader__card_two_top_label,
            cardTwoBottomLabel:
                data.quote_component[0]
                    .calculatorstepheader__card_two_bottom_label,
            cardTwoBottomContent:
                data.quote_component[0]
                    .calculatorstepheader__card_two_bottom_content,
            regText:
                data.quote_component[0].calculatorstepheader__regulation_text,
            ctaLabel: data.quote_component[0].calculatorstepheader__cta_label,
            ctaUrl: data.quote_component[0].calculatorstepheader__cta_url,
            productOneLabel:
                data.quote_component[0].calculatorquoteoneplan__product_1_label,
            productTwoLabel:
                data.quote_component[0].calculatorquoteoneplan__product_2_label,
            productThreeLabel:
                data.quote_component[0].calculatorquoteoneplan__product_3_label,
            productFourLabel:
                data.quote_component[0].calculatorquoteoneplan__product_4_label,
            pensionsTitle:
                data.quote_component[0].calculatorquotepensions__title,
            pensionsSubtitle:
                data.quote_component[0].calculatorquotepensions__subtitle,
            pensionsSalaryPercent:
                data.quote_component[0]
                    .calculatorquotepensions__salary_percentage,
            pensionsAnnualTotal:
                data.quote_component[0].calculatorquotepensions__annual_total,
            legendLabel1: data.quote_component[0].legend_label_1,
            legendLabel2: data.quote_component[0].legend_label_2,
            legendLabel3: data.quote_component[0].legend_label_3,
        },
        email: {
            from: data.email[0].sender,
            html: data.email[0].html,
            subject: data.email[0].subject,
        },
        type: data.calc_type,
        toolLabel: data.tool_label,
        toolCaption: data.tool_caption,
    }
}
