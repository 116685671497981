import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { StepperDots } from 'common/components/design-system/atoms/StepperDots'
import { theme } from 'common/components/design-system/theme'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import { CalculatorStepContents as CalculatorStepContentsProps } from '../../interfaces'
import Divider from './divider.svg'

export const CalculatorStepContents: React.FC<CalculatorStepContentsProps> = ({
    contents = [],
    contentHeading
}) => {
    const { palette } = theme
    const useStyles = makeStyles({
        content: {
            fontWeight: 500,
            fontFamily: 'Assistant',
            fontSize: '18px',
            lineHeight: '28px',
            color: palette.NEUTRAL_700
        },
        title: {
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '31.2px',
            color: '#4E4670'
        },
        text: {
            fontSize: '16px',
            lineHeight: '24px',
            color: '#766F90'
        },
        stepper: {
            justifyContent: 'flex-start',
            display: 'flex'
        },
        container: {
            paddingLeft: '24px',
            paddingTop: '18px'
        }
    })

    const classes = useStyles()

    return (
        <>
            <Text className={classes.text} html={true} label={contentHeading} />
            <Spacer variant='xs' />
            {contents.map(
                (
                    { content__title, content__icon, content__step_number },
                    index
                ) => (
                    <Grid container>
                        <Grid item xs={2}>
                            <img
                                src={content__icon}
                                alt='Tick Outline Simple'
                                style={{
                                    marginRight: 24
                                }}
                            />
                        </Grid>
                        <Grid item xs={10} className={classes.container}>
                            <div className={classes.stepper}>
                                {content__step_number && (
                                    <StepperDots
                                        total={content__step_number}
                                        current={0}
                                    />
                                )}
                            </div>
                            <Text
                                className={classes.title}
                                html={true}
                                label={content__title}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ paddingLeft: '28px' }}>
                            {index < contents.length - 1 ? (
                                <img src={Divider} alt='divider' />
                            ) : (
                                undefined
                            )}
                        </Grid>
                    </Grid>
                )
            )}
        </>
    )
}
