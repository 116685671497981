import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
import { icons } from './icons'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ButtonProps {
    classes: any
    label: string
    onClick?: () => void
    isDisabled?: boolean
    type?: string
    iconPosition?: string
    icon?: string
    size?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        borderRadius: '100px',
        border: '0px',
        display: 'flex',
        fontFamily: 'Assistant',
        fontWeight: 700,
        fontSize: '16px',
        letterSpacing: '0.64px',
        lineHeight: '18.75px',
        padding: '0px 24px',
        // minWidth: '147px',
        justifyContent: 'center',
        height: '44px',
        cursor: 'pointer',
        outline: 'none',
        alignItems: 'center',

        '& .hover': {
            display: 'none'
        },

        '& .pressed': {
            display: 'none'
        },

        '&:hover .active': {
            display: 'none'
        },
        '&:hover .hover': {
            display: 'block'
        },

        '&:active .hover': {
            display: 'none'
        },

        '&:active .active': {
            display: 'none'
        },

        '&:active .pressed': {
            display: 'block'
        }
    },
    small: {
        height: '32px',
        padding: '8px',
        fontSize: '14px',
        fontWeight: 600
    },
    brandPrimary: {
        backgroundColor: palette.BRAND_600_PRIMARY,
        color: palette.WHITE,

        '&:hover': {
            backgroundColor: palette.BRAND_800
        },

        '&:active': {
            backgroundColor: palette.BRAND_800
        }
    },
    vibrantPrimary: {
        backgroundColor: palette.VIBRANT_500_PRIMARY,
        color: palette.WHITE,

        '&:hover': {
            backgroundColor: palette.BRAND_600_PRIMARY
        },

        '&:active': {
            backgroundColor: palette.BRAND_600_PRIMARY
        }
    },
    secondary: {
        backgroundColor: palette.VIBRANT_200,
        color: palette.VIBRANT_500_PRIMARY,

        '&:hover': {
            backgroundColor: palette.VIBRANT_500_PRIMARY,
            color: palette.WHITE
        },

        '&:active': {
            backgroundColor: palette.BRAND_600_PRIMARY
        }
    },
    ghost: {
        backgroundColor: 'transparent',
        color: palette.VIBRANT_500_PRIMARY,

        '&:hover': {
            backgroundColor: palette.WHITE,
            boxShadow: '0px 0px 20px 0px rgba(82,97,172,0.24)'
        },

        '&:active': {
            backgroundColor: palette.WHITE,
            boxShadow: 'none',
            color: palette.VIBRANT_900
        }
    },
    buttonIcon: {
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: palette.WHITE,
            boxShadow: '0px 0px 20px 0px rgba(82,97,172,0.24)'
        },

        '&:active': {
            backgroundColor: '#fff',
            boxShadow: 'none'
            //color: palette.VIBRANT_900
        }
    },
    withIconRight: {
        padding: '0px 8px 0px 24px'
    },
    withIconLeft: {
        padding: '0px 24px 0px 8px'
    },
    withIconCenter: {
        padding: '8px'
    },
    disabledBrandPrimary: {
        backgroundColor: palette.NEUTRAL_200,
        cursor: 'not-allowed',
        color: palette.WHITE,
        '&:hover': {
            backgroundColor: palette.NEUTRAL_200
        },
        '&:active': {
            backgroundColor: palette.NEUTRAL_200
        }
    },
    disabled: {
        backgroundColor: palette.VIBRANT_100_SECONDARY,
        cursor: 'not-allowed',
        color: palette.WHITE,
        '&:hover': {
            backgroundColor: palette.VIBRANT_100_SECONDARY
        },
        '&:active': {
            backgroundColor: palette.VIBRANT_100_SECONDARY
        }
    },
    disabledGhost: {
        color: palette.NEUTRAL_200,
        cursor: 'not-allowed',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none'
        },
        '&:active': {
            backgroundColor: 'transparent',
            color: palette.NEUTRAL_200
        }
    },
    alignLeft: {
        justifyContent: 'flex-start',

        '& img': {
            marginRight: '16px'
        }
    },
    alignRight: {
        justifyContent: 'flex-end',

        '& img': {
            marginLeft: '16px'
        }
    },
    alignCenter: {
        justifyContent: 'center',

        '& img': {
            marginLeft: '0px'
        }
    },
    buttonCircle: {
        justifyContent: 'center',
        height: '48px',
        width: '48px',
        minWidth: '48px',

        '& img': {
            width: '24px'
        }
    }
}

export const getBackgroundIcon = (icon = '', type = 'brandPrimary') => {
    if (!icon) return null
    return icon
        ? {
              active: icons[icon][type]['active'],
              hover: icons[icon][type]['hover'],
              pressed: icons[icon][type]['pressed'],
              disabled: icons[icon][type]['disabled']
          }
        : null
}

export const ButtonElem: React.FC<ButtonProps> = ({ ...props }) => {
    const {
        classes,
        label,
        isDisabled,
        onClick,
        type,
        iconPosition,
        icon,
        size
    } = props
    const buttonIcon = getBackgroundIcon(icon, type)

    return (
        <button
            aria-label={label}
            onClick={isDisabled ? () => {} : onClick}
            className={`${classes.root}
            ${size === 'small' && classes.small}
            ${type === undefined ? classes.brandPrimary : classes[type]}
            ${icon !== undefined &&
                iconPosition === 'left' &&
                classes.withIconLeft}
            ${icon !== undefined &&
                iconPosition === 'right' &&
                classes.withIconRight}
            ${icon !== undefined &&
                iconPosition === 'center' &&
                classes.withIconCenter}
            ${isDisabled &&
                type !== 'brandPrimary' &&
                type !== 'ghost' &&
                type !== undefined &&
                classes.disabled}
            ${isDisabled &&
                (type === 'brandPrimary' || type === undefined) &&
                classes.disabledBrandPrimary}
            ${isDisabled && type === 'ghost' && classes.disabledGhost}
            ${label !== undefined &&
                (iconPosition === 'left'
                    ? classes.alignLeft
                    : iconPosition === 'right'
                    ? classes.alignRight
                    : classes.alignCenter)}
            ${label === undefined && classes.buttonCircle}
            `}
        >
            {(iconPosition === 'right' || iconPosition === undefined) &&
                label !== undefined &&
                label}
            {buttonIcon && (
                <>
                    <img
                        alt='icon'
                        src={buttonIcon.active}
                        className={`${classes.icon} `}
                    />

                    {/* {!isDisabled && (
                        <img
                            alt='icon'
                            src={buttonIcon.hover}
                            className={`${classes.icon} hover`}
                        />
                    )}
                    {!isDisabled && (
                        <img
                            alt='icon'
                            src={buttonIcon.pressed}
                            className={`${classes.icon} pressed`}
                        />
                    )}
                    {isDisabled && (
                        <img
                            alt='icon'
                            src={buttonIcon.disabled}
                            className={`${classes.icon} `}
                        />
                    )} */}
                </>
            )}
            {iconPosition === 'left' && label !== undefined && label}
        </button>
    )
}

export const Button = withStyles(styles)(ButtonElem)
