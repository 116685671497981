import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { Box, Theme, withStyles } from '@material-ui/core'
import { Styles } from '@material-ui/core/styles/withStyles'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import { NumberInput } from 'common/components/design-system/molecules/NumberInput'
import { Text } from 'common/components/molecules/Text'
import React, { useEffect } from 'react'
import { InputField } from '../../../../../design-system/molecules/inputField'
import { theme } from '../../../../theme'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepInput as CalculatorStepInputInterface } from '../../interfaces'

export interface CalculatorStepInputProps extends CalculatorStepInputInterface {
    updateData: (newData: { [key: string]: any }) => void
    classes: any
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    belowBoxText: {
        color: palette.BRAND_400,
        lineHeight: '100%',
        margin: '2%',
    },
}
//updateData function that puts a new value into the calculator
//when components mounts
export const CalculatorStep: React.FC<CalculatorStepInputProps> = ({
    classes,
    caption = '',
    data = {},
    fieldName,
    group,
    label,
    placeholder,
    title,
    tooltip,
    value,
    text,
    updateData,
    inputType,
    fieldLabel,
    fieldHelperText,
    fieldOptional,
}) => {
    useEffect(() => {
        updateData({ [fieldName]: '' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const update = (value: string) => {
        updateData({ [fieldName]: value })
    }
    return (
        <>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}

            {group && fieldLabel && (
                <TextDS color='monochrome.800' variant='label-md'>
                    {fieldLabel}
                </TextDS>
            )}

            <Box alignItems='center'>
                {inputType === 'text' && (
                    <InputField
                        onchange={(value) => update(value as string)}
                        placeholder={placeholder}
                    />
                )}
                {inputType === 'number' && (
                    <NumberInput
                        onchange={(value) => update(value as string)}
                        placeholder={placeholder}
                    />
                )}
            </Box>
            {group && fieldLabel && (
                <TextDS
                    color='monochrome.700'
                    variant='label-md'
                    sx={{ pr: '30px' }}
                >
                    {fieldHelperText}
                </TextDS>
            )}
            <Box style={{ marginTop: '24px' }}>
                {text && <Text className={classes.belowBoxText} label={text} />}
            </Box>
        </>
    )
}

export const CalculatorStepInput = withStyles(styles)(CalculatorStep)
