import { Box } from '@chakra-ui/react'
import { Card } from '@irishlife/ilgroupdesignsystem.atoms.card'
import { Text as TextDS } from '@irishlife/ilgroupdesignsystem.atoms.text'
import { CalculatorStepCommon } from 'common/components/design-system/organisms/CalculatorCms'
import {
    QuoteComponentData,
    QuoteComponentDataApi,
    QuoteComponentDataCms,
    QuoteComponentDataInput,
} from 'common/components/templates/Calculator/config/index'
import numeral from 'numeral'
import React from 'react'
import { CalculatorCards } from '../cards/calculatorCards'

export interface QuoteComponentDataBase extends QuoteComponentData {
    input: QuoteComponentDataInput
    api: QuoteComponentDataApi
    cms: QuoteComponentDataCms
}

export interface MortgageProtectionQuoteProps
    extends Partial<CalculatorStepCommon> {
    data: QuoteComponentDataBase
}

export const MortgageProtectionQuote: React.FC<MortgageProtectionQuoteProps> =
    ({ data: { input, api, cms } }) => {
        return (
            <>
                <Card mb={4} p='32px 24px 40px'>
                    <TextDS color='brand.600' variant='caption-lg' mb={1}>
                        {cms.quoteLabel}
                    </TextDS>
                    <TextDS variant='title-md' mb={4}>
                        {`€${Number(api.premInclLevy || 0).toFixed(2)}/month`}
                    </TextDS>
                    <Box display={'flex'}>
                        <TextDS mr={1}>Term: </TextDS>
                        <TextDS fontWeight='bold'>{input.term} years</TextDS>
                    </Box>
                    <Box display={'flex'}>
                        <TextDS mr={1}>Cover Amount: </TextDS>
                        <TextDS fontWeight='bold'>{`€${numeral(
                            input.lifeCoverAmt
                        ).format('0,000')}`}</TextDS>
                    </Box>
                    <Box display={'flex'}>
                        <TextDS mr={1}>Type: </TextDS>
                        <TextDS fontWeight='bold'>
                            {input.jointLife === 'TRUE'
                                ? `Two Person`
                                : `Single Person`}
                        </TextDS>
                    </Box>
                </Card>
                <Card mb={4} p='32px 24px 40px'>
                    <TextDS color='brand.600' variant='caption-lg' mb={10}>
                        {cms.cardTwoTopLabel}
                    </TextDS>
                    <Box display={'flex'}>
                        <TextDS mr={1}>Cost of Life Cover: </TextDS>
                        <TextDS fontWeight='bold'>
                            €{numeral(+api.prem - +api.fee).format('0.00')}
                        </TextDS>
                    </Box>
                    <Box display={'flex'}>
                        <TextDS mr={1}>Irish Life Policy Fee: </TextDS>
                        <TextDS fontWeight='bold'>
                            €{numeral(api.fee).format('0.00')}
                        </TextDS>
                    </Box>
                    <Box display={'flex'} mb={10}>
                        <TextDS mr={1}>1% Government Levy: </TextDS>
                        <TextDS fontWeight='bold'>
                            €{numeral(api.levy).format('0.00')}
                        </TextDS>
                    </Box>
                    <TextDS mb={10}>{cms.regText}</TextDS>
                    <TextDS variant='body-sm'>
                        {cms.cardTwoBottomContent}
                    </TextDS>
                </Card>
                <Box
                    display={'flex'}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={4}
                >
                    <CalculatorCards />
                </Box>

                {/* <div className={classes.box}>
                <a href={cms.ctaUrl}>
                    <Button label={cms.ctaLabel} icon='arrowSend'></Button>
                </a>
            </div> */}
            </>
        )
    }
