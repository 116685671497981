import ArrowDown200 from './arrow-down-small-monochrome-200.svg'
import ArrowDown500 from './arrow-down-small-500-primary.svg'
import ArrowDown900 from './arrow-down-small-900.svg'
import ArrowLeftCircle500 from './arrow-left-circle-500-primary.svg'
import ArrowLeftCircleWhite from './arrow-left-circle-white.svg'
import ArrowRightCircle500 from './arrow-right-circle-500-primary.svg'
import ArrowRightCircleWhite from './arrow-right-circle-white.svg'
import CalculatorPensionIcon from './icon-pension-calculator.svg'
import CancelSmall from './cancel-small.svg'
import CurrencyEuroMedium from './currency-euro-medium.svg'
import Divider from './divider.svg'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FlagIrelandMedium from './flag-ireland-medium.svg'
import FlagIrelandSmall from './flag-ireland-small.svg'
import FlagUKMedium from './flag-uk-medium.svg'
import FlagUKSmall from './flag-uk-small.svg'
import Info from './info.svg'
import InfoSmall from './info-small.svg'
import InputBase from '@material-ui/core/InputBase'
import PlusGhostActive from './plus-ghost-active.svg'
import PlusGhostDisabled from './plus-ghost-disabled.svg'
import PlusGhostHover from './plus-ghost-hover.svg'
import PlusGhostPressed from './plus-ghost-pressed.svg'
import PlusWhite from './plus-white.svg'
import ProductHealth from './icon-health.svg'
import ProductInvestment from './icon-investment.svg'
import ProductLifeInsurance from './icon-lifeinsurance.svg'
import ProductPension from './icon-pension.svg'
import RangeWhite from './range-white.svg'
import TickSmall200 from './tick-small-monochrome-200.svg'
import TickSmall500 from './tick-small-500-primary.svg'
import TickSmall900 from './tick-small-900.svg'
import TickSmallGreen from './tick-small-green.svg'
import TimesSmall from './times-small.svg'
import UmbrellaCircle from './umbrella-circle.svg'

export const ARROW_DOWN_200 = ArrowDown200
export const ARROW_DOWN_500 = ArrowDown500
export const ARROW_DOWN_900 = ArrowDown900
export const ARROW_LEFT_CIRCLE_500 = ArrowLeftCircle500
export const ARROW_LEFT_CIRCLE_WHITE = ArrowLeftCircleWhite
export const ARROW_RIGHT_CIRCLE_500 = ArrowRightCircle500
export const ARROW_RIGHT_CIRCLE_WHITE = ArrowRightCircleWhite
export const CALCULATOR_PENSION_ICON = CalculatorPensionIcon
export const CANCEL_SMALL = CancelSmall
export const CURRENCY_EURO_MEDIUM = CurrencyEuroMedium
export const DIVIDER = Divider
export const EXPAND_MORE = ExpandMoreIcon
export const FLAG_IRELAND_MEDIUM = FlagIrelandMedium
export const FLAG_IRELAND_SMALL = FlagIrelandSmall
export const FLAG_UK_MEDIUM = FlagUKMedium
export const FLAG_UK_SMALL = FlagUKSmall
export const INFO = Info
export const INFO_SMALL = InfoSmall
export const INPUT_BASE = InputBase
export const PLUS_GHOST_ACTIVE = PlusGhostActive
export const PLUS_GHOST_DISABLED = PlusGhostDisabled
export const PLUS_GHOST_HOVER = PlusGhostHover
export const PLUS_GHOST_PRESSED = PlusGhostPressed
export const PLUS_WHITE = PlusWhite
export const PRODUCT_HEALTH = ProductHealth
export const PRODUCT_INVESTMENT = ProductInvestment
export const PRODUCT_LIFEINSURANCE = ProductLifeInsurance
export const PRODUCT_PENSION = ProductPension
export const RANGE_WHITE = RangeWhite
export const TICK_SMALL_200 = TickSmall200
export const TICK_SMALL_500 = TickSmall500
export const TICK_SMALL_GREEN = TickSmallGreen
export const TICK_SMALL_900 = TickSmall900
export const TIMES_SMALL = TimesSmall
export const UMBRELLA_CIRCLE = UmbrellaCircle
