import React, { useState } from 'react'
import { InputField } from '../inputField'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmailInputProps {
    onBlur?: (val: string | number) => void
    isOptional?: boolean
    isValid?: boolean
    isDisabled?: boolean
    value?: string
    placeholder?: string
    label?: string
}

export const EmailInput: React.FC<EmailInputProps> = ({ ...props }) => {
    const {
        value,
        isDisabled,
        onBlur,
        isOptional,
        isValid,
        label,
        placeholder
    } = props
    const [emailValid, setValid] = useState(isValid)

    const validateInput = (val: string) => {
        const validate = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return validate.test(String(val).toLowerCase())
    }

    const handleBlur = (val: any) => {
        const isEmailValid = validateInput(val)
        setValid(isEmailValid)
        onBlur && onBlur(val)
    }
    return (
        <InputField
            name='email'
            isDisabled={isDisabled}
            isOptional={isOptional}
            value={value}
            type='email'
            helper={
                emailValid === false
                    ? 'The value entered is not a valid email address'
                    : ''
            }
            label={label}
            placeholder={placeholder}
            onBlur={handleBlur}
            isValid={emailValid}
        />
    )
}
