import { Button } from 'common/components/design-system/atoms/button'
import React from 'react'
import { Caption } from 'common/components/design-system/atoms/Caption'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import { Text } from 'common/components/design-system/organisms/CalculatorCms/Text'
import numeral from 'numeral'
import { CalculatorStepCommon } from 'common/components/design-system/organisms/CalculatorCms'
import {
    QuoteComponentData,
    QuoteComponentDataInput,
    QuoteComponentDataApi,
    QuoteComponentDataCms
} from 'common/components/templates/Calculator/config/index'
import { getQuoteStyles } from './configFunctions'

export interface QuoteComponentDataBase extends QuoteComponentData {
    input: QuoteComponentDataInput
    api: QuoteComponentDataApi
    cms: QuoteComponentDataCms
}

export interface LifeQuoteProps extends Partial<CalculatorStepCommon> {
    data: QuoteComponentDataBase
}

export const LifeQuote: React.FC<LifeQuoteProps> = ({
    data: { input, api, cms }
}) => {
    const useStyles = getQuoteStyles()
    const classes = useStyles()
    let type = ''
    switch (api.calcType) {
        case 'life-long-protection':
            type = 'Long Life'
            break
        case 'mortgage-protection':
            type = 'Mortgage Protection'
            break
        case 'term-life-protection':
            type = 'Term Life'
            break
        case 'pensions':
            type = 'Pensions'
            break
        default:
            break
    }
    return (
        <>
            <div className={classes.card}>
                <SmallTitle
                    text={`€${Number(api.premInclLevy || 0).toFixed(2)}/month`}
                />
                <div className={classes.box}>
                    <Caption text={cms.quoteLabel} />
                    <div className={classes.content}>
                        {input.term && (
                            <>
                                <span className={classes.detailsLabels}>
                                    Term{' '}
                                </span>
                                <span>{input.term} years</span>
                                <br />
                            </>
                        )}
                        <span className={classes.detailsLabels}>
                            Cover Amount
                        </span>
                        <span>{` €${numeral(input.lifeCoverAmt).format(
                            '0,000'
                        )}`}</span>
                        <br />
                        <span className={classes.detailsLabels}>Type </span>
                        <span>
                            {input.jointLife === 'TRUE'
                                ? `Two Person ${type}`
                                : `Single Person ${type}`}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <Text className={classes.detailsLabels}>{cms.regText}</Text>
                </div>
            </div>
            <div className={classes.card}>
                <div className={classes.box}>
                    <Caption text={cms.cardTwoTopLabel} />
                    <div className={classes.content}>
                        <span>{`Cost of Life Cover: €${numeral(
                            +api.prem - +api.fee
                        ).format('0.00')}`}</span>
                        <br />
                        <span>
                            {`Irish Life Policy Fee: €${numeral(api.fee).format(
                                '0.00'
                            )}`}
                        </span>
                        <br />
                        <span>
                            {`1% Government Levy: €${numeral(api.levy).format(
                                '0.00'
                            )}`}
                        </span>
                    </div>
                </div>
                <div className={classes.box}>
                    <Caption text={cms.cardTwoBottomLabel} />
                    <div className={classes.content} id='span-id'>
                        {cms.cardTwoBottomContent}
                    </div>
                </div>
            </div>
            <div className={classes.box}>
                <a href={cms.ctaUrl}>
                    <Button label={cms.ctaLabel} icon='arrowSend'></Button>
                </a>
            </div>
        </>
    )
}
