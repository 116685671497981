import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import React, { useState } from 'react'
import { theme } from '../../../../theme'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepBinaryChoice as CalculatorStepBinaryChoiceInterface } from '../../interfaces'

export interface CalculatorStepBinaryChoiceProps
    extends CalculatorStepBinaryChoiceInterface {
    updateData: (newData: { [key: string]: any }) => void
}

export const CalculatorStepBinaryChoice: React.FC<CalculatorStepBinaryChoiceProps> = ({
    caption = '',
    codename,
    data = {},
    fieldName,
    group,
    label,
    options = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
    ],
    title,
    tooltip,
    updateData
}) => {
    const [state, setState] = useState<string | undefined>(data[fieldName])
    const update = (value: string) => {
        setState(value)
        updateData({ [fieldName]: value })
    }
    const { palette } = theme
    const useStyles = makeStyles({
        root: {
            display: 'flex'
        },
        button: {
            color: palette.BRAND_900,
            backgroundColor: palette.NEUTRAL_50,
            fontWeight: 700,
            fontFamily: 'Assistant',
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: '4px',
            border: '0px solid !important',
            width: '124px',
            height: '52px',
            margin: '8px',
            boxShadow: '0px',

            '&:hover': {
                backgroundColor: palette.ACCENT_500_PRIMARY,
                color: palette.WHITE
            },
            '&:focus': {
                backgroundColor: palette.ACCENT_500_PRIMARY,
                color: palette.WHITE
            },
            '&:disabled': {
                color: palette.NEUTRAL_400,
                backgroundColor: `${palette.NEUTRAL_50} !important`
            }
        },
        selected: {
            backgroundColor: palette.ACCENT_500_PRIMARY,
            color: palette.WHITE
        }
    })
    const classes = useStyles()

    return (
        <Box key={codename}>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}
            <Box display='flex'>
                <Button
                    color={
                        state === (options[0].value || options[0].label)
                            ? 'secondary'
                            : undefined
                    }
                    disableElevation
                    fullWidth
                    onClick={() => update(options[0].value || options[0].label)}
                    size='large'
                    variant={
                        state === (options[0].value || options[0].label)
                            ? 'contained'
                            : 'outlined'
                    }
                    className={`${classes.button} ${
                        state === (options[0].value || options[0].label)
                            ? classes.selected
                            : ''
                    }`}
                >
                    {options[0].label}
                </Button>
                <Box m={1} />
                <Button
                    color={
                        state === (options[1].value || options[1].label)
                            ? 'secondary'
                            : undefined
                    }
                    disableElevation
                    fullWidth
                    onClick={() => update(options[1].value || options[1].label)}
                    size='large'
                    variant={
                        state === (options[1].value || options[1].label)
                            ? 'contained'
                            : 'outlined'
                    }
                    className={`${classes.button} ${
                        state === (options[1].value || options[1].label)
                            ? classes.selected
                            : ''
                    }`}
                >
                    {options[1].label}
                </Button>
            </Box>
        </Box>
    )
}
