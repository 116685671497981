import { makeStyles } from '@material-ui/core/styles'
import { theme } from 'common/components/design-system/theme'
import React from 'react'
import { CalculatorStepText as CalculatorStepTextProps } from '../../interfaces'

export const CalculatorStepText: React.FC<CalculatorStepTextProps> = ({
    content,
}) => {
    const { palette } = theme
    const useStyles = makeStyles({
        content: {
            fontWeight: 500,
            fontFamily: 'Assistant',
            fontSize: '18px',
            lineHeight: '28px',
            color: palette.NEUTRAL_700,
            marginTop: '40px',
            '& a': {
                color: '#3681d9',
                textDecoration: 'underline',
            },
        },
    })
    const classes = useStyles()
    const createMarkup = (content: string) => {
        return { __html: content }
    }
    return (
        <div
            className={classes.content}
            dangerouslySetInnerHTML={createMarkup(content)}
        ></div>
    )
}
