import { Box } from '@material-ui/core'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import { EmailInput } from 'common/components/design-system/molecules/EmailInput'
import React from 'react'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepEmail as CalculatorStepEmailInterface } from '../../interfaces'

export interface CalculatorStepEmailProps extends CalculatorStepEmailInterface {
    updateData: (newData: { [key: string]: any }) => void
}

export const CalculatorStepEmail: React.FC<CalculatorStepEmailProps> = ({
    data = {},
    fieldName,
    group,
    title,
    tooltip,
    options = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
    ],
    placeholder,
    updateData
}) => {


    const update = (value: string | number) => {
        updateData({ [fieldName]: value })
    }

    return (
        <>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}

                <Box alignItems='center'>
                    <EmailInput placeholder={placeholder} onBlur={update} />
                </Box>

        </>
    )
}
