import SliderMui from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { theme } from '../../theme'
import RANGE_WHITE from './assets/range-white.svg'

export interface SliderProps {
    type?: string
    isDisabled?: boolean
    value?: string | number
    onChange?: (value: any) => void
    min: number
    max: number
    step: number
    showThumb: boolean
}

const { palette } = theme

const SliderStyled = withStyles({
    root: {
        paddingTop: '30px',
        color: palette.VIBRANT_500_PRIMARY,
    },
    track: {
        height: '4px',
        color: palette.VIBRANT_500_PRIMARY,
    },
    rail: {
        height: '4px',
        color: palette.BRAND_300_SECONDARY,
    },
    thumb: {
        height: '20px',
        width: '20px',
        marginTop: '-9px',
        backgroundColor: palette.VIBRANT_500_PRIMARY,
        borderRadius: '100%',

        '&:hover': {
            color: palette.VIBRANT_700,
            height: '20px',
            width: '20px',
        },

        '&:active': {
            color: palette.VIBRANT_700,
            height: '20px',
            width: '20px',
        },

        '&[data-type="handle"]': {
            height: '40px',
            width: '40px',
            marginTop: '-18px',
            backgroundImage: `url(${RANGE_WHITE})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    valueLabel: {
        left: 'calc(-50% + 5px)',
        top: -29,
        width: '40px',
        height: '44px',
    },
    disabled: {
        cursor: 'not-allowed',
        '& .MuiSlider-rail': {
            color: palette.BRAND_200,
        },
        '& .MuiSlider-track': {
            color: palette.BRAND_300_SECONDARY,
        },
        '& .MuiSlider-thumb': {
            color: palette.BRAND_300_SECONDARY,
            height: '20px',
            width: '20px',
            marginTop: '-9px',
            cursor: 'not-allowed',
        },

        '& [data-type="handle"]': {
            backgroundColor: palette.BRAND_200,
            height: '40px',
            width: '40px',
            marginTop: '-18px',
            cursor: 'not-allowed',
        },
    },
})(SliderMui)

export const Thumb = ({ ...props }) => (
    <span data-type='handle' {...props}></span>
)

export const formatValue = (value: any) =>
    value === undefined ? 0 : parseInt(value)

export const Slider: React.FC<SliderProps> = ({ ...props }) => {
    const {
        type,
        isDisabled,
        value,
        onChange,
        min,
        max,
        step,
        showThumb,
    } = props

    const [sliderValue, setValue] = useState(
        Array.isArray(value) ? value : formatValue(value)
    )
    useEffect(
        () => setValue(Array.isArray(value) ? value : formatValue(value)),
        [value]
    )
    const sliderType = type === undefined ? 'tooltip' : type
    const handleChange = (e: any, value: any) => {
        setValue(value)
        onChange && onChange(value)
    }
    const valueText = (value: any) => value

    return (
        <SliderStyled
            defaultValue={sliderValue}
            getAriaValueText={valueText}
            aria-labelledby='discrete-slider-always'
            step={step}
            valueLabelDisplay={sliderType === 'tooltip' ? 'auto' : 'off'}
            disabled={isDisabled}
            onChangeCommitted={handleChange}
            max={max}
            min={min}
            value={sliderValue}
            ThumbComponent={showThumb ? Thumb : 'span'}
        />
    )
}
