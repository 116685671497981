import { Box } from '@material-ui/core'
import React from 'react'
import { CalculatorStepMobileGroup as CalculatorStepMobileGroupProps } from '../../interfaces'
import { CalculatorStep } from '../CalculatorStep/CalculatorStep'

export const CalculatorStepMobileGroup: React.FC<CalculatorStepMobileGroupProps> =
    ({ steps, updateData }) => (
        <>
            {steps.map((step, index) => {
                return (
                    <Box key={step.codename} mt={index ? 10 : 0}>
                        <CalculatorStep
                            {...step}
                            updateData={updateData}
                            group
                        />
                    </Box>
                )
            })}
        </>
    )
