import { Box } from '@chakra-ui/react'
import { Grid } from '@material-ui/core'
import { CardData } from 'common/components/design-system/molecules/CardData'
import { CalculatorStepCommon } from 'common/components/design-system/organisms/CalculatorCms'
import { CollapsableContainer } from 'common/components/design-system/organisms/CollapsableContainer'
import { Counter } from 'common/components/molecules/Counter'
import { Spacer } from 'common/components/molecules/Spacer'
import { Switch } from 'common/components/molecules/Switch'
import { Text } from 'common/components/molecules/Text'
import { Textbox } from 'common/components/molecules/Textbox'
import { AdvisorBooking } from 'common/components/organisms/AdvisorBooking'
import { Alert } from 'common/components/organisms/Alerts/Alert'
import { ContactLeft } from 'common/components/organisms/Contact/ContactLeft'
import { JumbotronCallToAction } from 'common/components/organisms/Jumbotron/JumbotronCallToAction'
import {
    QuoteComponentDataApi,
    QuoteComponentDataCms,
} from 'common/components/templates/Calculator/config/index'
import { useDesktop } from 'common/hooks/useDesktop'
import { ADVISOR_BOOKING_FLAG, API_URL } from 'core'
import numeral from 'numeral'
import React, { useEffect, useMemo, useState } from 'react'
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import { Legend } from '../Legend/Legend'
import { APIrequest } from './APIrequests'
import { getQuoteStyles } from './configFunctions'
import {
    getAPIparamaters,
    getQuote,
    getUser,
    getValueFromPercentage,
} from './PensionCalculator'
import { API, Quote, QuoteComponentDataInput, User } from './PensionQuote'

const pensionAPI: string = `${API_URL}/myonlineservices/servlet/submitPensionQuote`
const maxCounterValue: number = 40
const minCounterValue: number = 0

export interface QuoteComponentDataBase {
    input: QuoteComponentDataInput
    api: QuoteComponentDataApi
    cms: QuoteComponentDataCms
}

export interface QuoteDisplayProps {
    cms: QuoteComponentDataCms
    yearQuote: Quote
    monthQuote: Quote
}

export interface PensionsQuoteProps extends Partial<CalculatorStepCommon> {
    data: QuoteComponentDataBase
}

export const PensionsQuote: React.FC<PensionsQuoteProps> = ({
    data: { input, api, cms },
}) => {
    const contactForm = {
        tabName: '',
        heading: '',
        titleForm: '',
        descriptionForm:
            'Would you like to learn more about your pension? Enter your details below to book an appointment with a financial advisor from Irish Life Financial Services.',
        firstLabelForm: 'First and last name*',
        secondLabelForm: 'Phone number*',
        footerDescriptionForm:
            'Irish Life Financial Services Limited is tied to Irish Life Assurance plc for life and pensions. Irish Life Financial Services Limited is regulated by the Central Bank of Ireland.',

        noteDescriptionForm: `Your personal details will only be used to deal with your request. See the Irish Life Financial Services <a href="/privacy-notice">privacy notice</a> for your rights and how your information is used.`,
        pickTimeSlotLabelForm: 'Pick a time slot',
    }
    const jumboTron = {
        analyticsId: '',
        label: 'Book your consultation',
    }
    const [showConfirmation, setShowConfirmation] = useState(false)
    const toggleConfirmation = () => {
        setShowConfirmation(!showConfirmation)
    }
    const confirmation = {
        title: 'Callback confirmed',
        subTitle:
            'Callbacks will come from Irish Life Financial Services (ILFS)',
        description:
            'We recorded your request. One of our colleagues will cal the mobile number you provided to us',
        noteMessage:
            'Please note: callbacks come from an 042 number. In the intereste of customer services we will record and monitor calls. We will only use your personal details for the purpose of dealing with your request. Your privacy is important to us. Callbacks are available Monday-Friday.',
        toggleConfirmation,
    }

    const [quotePercentage, setQuotePercentage] = useState(
        input.employeePercentageContribution > 0
            ? input.employeePercentageContribution
            : input.targetPercentageContribution
    )
    const setPercentageValue = function (val: number) {
        setQuotePercentage(val)
    }
    const [quoteSalaryContribution, setQuoteSalaryContribution] = useState(
        input.employeeSalaryContribution > 0
            ? input.employeeSalaryContribution
            : input.targetSalaryContribution
    )
    const setSalaryContributionValue = function (val: number) {
        setQuoteSalaryContribution(val)
    }

    const useStyles = getQuoteStyles()
    const classes = useStyles()

    const user: User = useMemo(
        () =>
            getUser(
                input.age1,
                input.salary,
                input.statePension,
                input.contributionStart > 0
                    ? Number(input.contributionStart) + Number(input.age1)
                    : input.age1,
                input.currentPensionVal,
                getValueFromPercentage(
                    input.salary,
                    quotePercentage,
                    quoteSalaryContribution
                ),
                getValueFromPercentage(
                    input.salary,
                    input.employerPercentageContribution,
                    input.employerSalaryContribution
                ),
                input.yearlyRetirementIncome
            ),
        [input, quotePercentage, quoteSalaryContribution]
    )

    const [yearQuote, setYearQuote] = useState<Quote>(
        getQuote(user.yearlyPaymentTargetValue, 0, user.statePension * 12)
    )
    const [monthQuote, setMonthQuote] = useState<Quote>(
        getQuote(
            Number((user.yearlyPaymentTargetValue / 12).toFixed(2)),
            0,
            user.statePension
        )
    )

    useEffect(() => {
        const apiRequest: API = getAPIparamaters(
            user.startPensionAge,
            user.income,
            Number(user.salaryContribution) + Number(user.employerContribution),
            user.yearlyPaymentTargetValue,
            user.currentPensionValue
        )

        APIrequest(pensionAPI, apiRequest.type5year).then((response) => {
            if (response.quoteData !== undefined) {
                const yearResponse = getQuote(
                    user.yearlyPaymentTargetValue,
                    response.quoteData.annualPension,
                    user.statePension * 12
                )
                const monthResponse = getQuote(
                    Number((user.yearlyPaymentTargetValue / 12).toFixed(2)),
                    Number(
                        (Number(response.quoteData.annualPension) / 12).toFixed(
                            2
                        )
                    ),
                    user.statePension
                )
                setYearQuote(yearResponse)
                setMonthQuote(monthResponse)
            }
        })
    }, [input, user])

    const coverRequirements = [
        {
            Component: CardData,
            id: 1,
            props: {
                label: `Assumptions`,
                data: [
                    `Pensions projection based on a retirement age of 66.`,
                    `Regular contributions go up by 1.5% each year over the term of your plan. Contributions are invested in a standard personal pension product, which has a fund management charge of 0.75% per annum and a contribution charge of 4% of each contribution.`,
                    `The projected values assume an investment return before retirement of 3.40% per annum. This rate is for illustrative purposes only and is not guaranteed. Actual investment growth will depend on the performance of the underlying investments and may be more or less than illustrated.`,
                    `An inflation rate of 1.5% per annum is used to express future values in today's terms.`,
                    `The estimated annuities quoted are payable monthly in advance. The guaranteed period is 5 years, so in the event of early death during these five years, the income will continue to be paid for the balance of this period.`,
                    `Annuity payments increase by 1% per annum.`,
                    `The annuity rate at your retirement date will depend on long-term interest rates and life expectancy assumptions at that time and is likely to be different from the annuity rate used in the illustration. Different annuity options can be chosen at retirement.`,
                    `Under the Finance Act 2012, the age at which people qualify for the state pension is 66 years of age.`,
                    `The state pension (Contributory) for a single person is €13,171 per annum as of 7th January 2022.`,
                ],
            },
        },
    ]
    const taxRelief = [
        {
            Component: CardData,
            id: 2,
            props: {
                label: `Tax Relief`,
                data: [
                    ` Tax relief is not guaranteed. Rates are current as of January 2022.`,
                    `To claim tax relief you can apply to your inspector of taxes to adjust your tax credits. Contributions deducted from salary will receive immediate tax relief. To be eligible to claim income tax relief, your income must be taxable either Schedule E or Schedule D (Case l or ll).`,
                    ` If you are self-employed, you must include your pension contributions in your self-assessment tax returns in order to obtain income tax relief. Any employer contributions will receive tax relief in the year the contribution is made.`,
                    `Pension income in retirement is subject to income tax at your highest rate on withdrawal, Universal Social Charge, PRSI (if applicable) and any other charges or levies (“tax”) applicable at this time.`,
                ],
            },
        },
    ]
    const desktop = useDesktop()

    const showAdvisorBooking = ADVISOR_BOOKING_FLAG
    const showContactForm = !ADVISOR_BOOKING_FLAG

    return (
        <Box sx={{ maxWidth: desktop ? '100%' : 325 }}>
            <div className={classes.card}>
                <QuoteDisplay
                    cms={cms}
                    yearQuote={yearQuote}
                    monthQuote={monthQuote}
                />
                {input.employeePercentageContribution > 0 ||
                input.targetPercentageContribution > 0 ? (
                    <Grid item xs={12} md={12}>
                        <Text
                            className={classes.subtitle}
                            label={cms.pensionsSubtitle}
                        />
                        <Spacer variant='xs' />
                        <Counter
                            maxValue={maxCounterValue}
                            minValue={minCounterValue}
                            initialValue={quotePercentage}
                            handleToggle={setPercentageValue}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
                {input.employeeSalaryContribution > 0 ||
                input.targetSalaryContribution > 0 ? (
                    <Grid item xs={12} md={12}>
                        <Text
                            className={classes.subtitle}
                            label={cms.pensionsSubtitle}
                        />
                        <Spacer variant='xs' />
                        <Textbox
                            handleToggle={setSalaryContributionValue}
                            label={quoteSalaryContribution}
                        />
                    </Grid>
                ) : (
                    <></>
                )}
            </div>
            <JumbotronCallToAction
                label={cms.ctaLabel}
                url={cms.ctaUrl}
                analyticsId=''
            />
            <Spacer variant='xs' />
            <CollapsableContainer
                body={coverRequirements}
                label=''
                title='Assumptions'
                isCollapsed={desktop ? false : true}
            />
            <Spacer variant='xs' />
            <CollapsableContainer
                body={taxRelief}
                label=''
                title='Tax Relief'
                isCollapsed={desktop ? false : true}
            />
            <Spacer variant='xs' />
            <Alert text='Warning: These figures are estimates only. They are not a reliable guide to the future performance of this investment. ' />
            <Alert text='Warning: The value of your investment may go down as well as up.' />
            <Alert text='Warning: If you invest in this product you may lose some or all of the money you invest.' />
            <Alert text='Warning: If you invest in this product you will not have any access to your money until age 60 and/or you retire.' />
            <Spacer variant='xs' />

            <Box
                id='contact-form'
                sx={{ display: showContactForm ? 'block' : 'none' }}
            >
                <ContactLeft
                    confirmation={confirmation}
                    callToAction={jumboTron}
                    formValues={contactForm}
                />
            </Box>
            <AdvisorBooking
                slim
                sx={{ display: showAdvisorBooking ? 'block' : 'none' }}
            />
        </Box>
    )
}

export const QuoteDisplay = ({
    cms,
    yearQuote,
    monthQuote,
}: QuoteDisplayProps) => {
    const useStyles = getQuoteStyles()
    const classes = useStyles()
    const desktop = useDesktop()
    let [toggleYearMonthValue, setToggleYearMonthValue] = useState(true)
    let quote: Quote
    const toggleYearMonth = () => {
        toggleYearMonthValue
            ? setToggleYearMonthValue(false)
            : setToggleYearMonthValue(true)
    }
    if (toggleYearMonthValue) {
        quote = monthQuote
    } else {
        quote = yearQuote
    }

    return (
        <div className={classes.card}>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Text
                        label={cms.pensionsTitle}
                        style={{ fontSize: '28px' }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                        display: 'flex',
                        justifyContent: desktop ? 'flex-end' : 'none',
                    }}
                >
                    <Switch
                        isOn={toggleYearMonthValue}
                        handleToggle={toggleYearMonth}
                    />
                </Grid>
                <Spacer variant='md' />
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text
                        style={{
                            fontSize: desktop ? 24 : 16,
                        }}
                        label='Pension Projection'
                    />
                    <Text
                        style={{
                            fontSize: desktop ? 24 : 16,
                            margin: 0,
                        }}
                        label='Your Target'
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                        display: 'flex',
                        flexFlow: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    <Text
                        style={{
                            fontSize: desktop ? 28 : 24,
                        }}
                        label={`€${numeral(quote.projection).format('0,000')}`}
                    />
                    <Text
                        style={{
                            fontSize: desktop ? 28 : 24,
                            margin: 0,
                        }}
                        label={`€${numeral(quote.target).format('0,000')}`}
                    />
                </Grid>
                <Spacer variant='sm' />
                <Grid item xs={6} md={12}>
                    {desktop ? (
                        <ResponsiveContainer height={125}>
                            <BarChart
                                layout='vertical'
                                data={quote.barChart}
                                margin={{ left: 50, right: 50 }}
                                stackOffset='expand'
                            >
                                <XAxis hide type='number' />
                                <YAxis
                                    type='category'
                                    dataKey='name'
                                    stroke='#333'
                                    hide
                                />
                                <Bar
                                    dataKey='statePensionValue'
                                    fill='#5261ac'
                                    stackId='a'
                                ></Bar>
                                <Bar
                                    dataKey='userContributedPensionProjection'
                                    fill='#0f9fbf'
                                    stackId='a'
                                ></Bar>
                                <Bar
                                    dataKey='incomeGap'
                                    fill='#e8e7e8'
                                    stackId='a'
                                ></Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    ) : (
                        <ResponsiveContainer height={230}>
                            <BarChart
                                height={300}
                                width={250}
                                data={quote.barChart}
                            >
                                <XAxis dataKey='name' hide />
                                <YAxis hide />
                                <Bar
                                    dataKey='statePensionValue'
                                    stackId='a'
                                    fill='#5261ac'
                                />
                                <Bar
                                    dataKey='userContributedPensionProjection'
                                    stackId='a'
                                    fill='#0f9fbf'
                                />
                                <Bar
                                    dataKey='incomeGap'
                                    stackId='a'
                                    fill='#e8e7e8'
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                </Grid>
                {/* <Bar data={data} options={options} /> */}

                <Grid
                    item
                    xs={6}
                    md={12}
                    style={{
                        display: desktop ? 'flex' : 'block',
                        justifyContent: 'space-around',
                    }}
                >
                    {/* `€${numeral(
                              toggleYearMonthValue ? quote.month.barChart[0].statePension.toString() : quote.year.barChart[0].statePension.toString()
                          ).format('0,000')}`, */}
                    <Legend
                        label='State Pension'
                        color='#5261AC'
                        value={`€${numeral(
                            quote.barChart[0].statePensionValue.toString()
                        ).format('0,000')}`}
                    />
                    <Legend
                        label='Your Pension'
                        color='#0f9fbf'
                        value={`€${numeral(
                            quote.barChart[0].userContributedPensionProjection.toString()
                        ).format('0,000')}`}
                    />
                    <Legend
                        label='Retirement Income Gap'
                        color='#e8e8e8'
                        value={`€${numeral(
                            quote.barChart[0].incomeGap.toString()
                        ).format('0,000')}`}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
