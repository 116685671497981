import Fade from '@material-ui/core/Fade'
import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { SmallTitle } from '../../atoms/SmallTitle'
import { theme } from '../../theme'
import INFO_SMALL from './assets/info-small.svg'
import INFO from './assets/info.svg'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TooltipProps {
    classes: any
    title?: string
    content?: string
    alignRight?: boolean
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        position: 'relative'
    },
    icon: {
        height: '48px',
        width: '48px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${INFO_SMALL})`,
        cursor: 'pointer'
    },
    tooltipIcon: {
        backgroundImage: `url(${INFO})`,
        height: '48px',
        width: '48px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        marginBottom: '25px'
    },
    tooltipContent: {
        backgroundColor: palette.WHITE,
        padding: '32px 24px 40px',
        borderRadius: '16px',
        boxShadow: '4px 0px 24px rgba(82, 97, 172, 0.08);',
        minHeight: '214px',
        fontFamily: 'Assistant',
        maxWidth: '100%',
        position: 'absolute',
        top: '0px',
        zIndex: 50
    },
    content: {
        fontWeight: 500,
        fontFamily: 'Assistant',
        fontSize: '18px',
        lineHeight: '28px',
        color: palette.NEUTRAL_700,
        marginTop: '40px'
    },
    iconRight: {
        position: 'absolute',
        right: '-9px',
        top: '-18px'
    },
    contentRight: {
        // left: '0px'
    }
}

export const TooltipElem: React.FC<TooltipProps> = ({ ...props }) => {
    const { title, classes, content, alignRight } = props
    const [isActive, setActive] = useState(false)
    const [isMouseEnter, setMouseEnter] = useState(false)
    const [timeout, assignTImeout] = useState<any>(null)

    useEffect(() => {
        if (isMouseEnter) assignTImeout(clearTimeout(timeout))
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('touchstart', handleClickOutside)
    }, [isMouseEnter, timeout])

    const handleClickOutside = (event: any) => {
        const element = document.getElementById('tooltip')
        if (
            element &&
            event.target !== element &&
            !element.contains(event.target)
        )
            setActive(false)
    }

    const scheduleHide = () =>
        assignTImeout(setTimeout(() => setActive(false), 10000))

    const handleClick = () => {
        setActive(true)
        scheduleHide()
    }
    const onMouseEnter = () => setMouseEnter(true)

    const onMouseLeave = () => {
        setMouseEnter(false)
        scheduleHide()
    }

    const createMarkup = (content: string) => {
        return { __html: content }
    }

    return (
        <div className={classes.root}>
            <div
                className={`${classes.icon} ${alignRight && classes.iconRight}`}
                onClick={handleClick}
            />
            <Fade in={isActive}>
                <div
                    id='tooltip'
                    className={classes.tooltipContent}
                    onTouchStart={onMouseEnter}
                    onTouchEnd={onMouseLeave}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <div className={classes.tooltipIcon} />
                    {title && <SmallTitle text={title} />}
                    {content && (
                        <div
                            className={`${classes.content} ${alignRight &&
                                classes.contentRight}`}
                            dangerouslySetInnerHTML={createMarkup(content)}
                        ></div>
                    )}
                </div>
            </Fade>
        </div>
    )
}

export const Tooltip = withStyles(styles)(TooltipElem)
