import { Quote, User, API, APItype5 } from './PensionQuote'

const monthlyStatePension: number = 1101.43

export const getValueFromPercentage = (
    income: number,
    percentage?: number,
    value?: number
) => {
    return percentage && percentage > 0
        ? (percentage / 100) * (income / 12)
        : value
        ? value
        : 0
}

export const getUser = (
    age: number,
    income: number,
    statePensionEligibility: string,
    startPensionAge: number,
    currentPensionValue: number,
    salaryContribution: number,
    employerContribution: number,
    yearlyPaymentTargetValue: number
) => {
    const user: User = {
        age,
        income,
        statePension: statePensionEligibility === 'Y' ? monthlyStatePension : 0,
        startPensionAge,
        currentPensionValue,
        salaryContribution,
        employerContribution,
        yearlyPaymentTargetValue
    }
    return user
}

export const getQuote = (
    target: number,
    userContributedPension: any,
    statePensionValue: number
) => {
    const userContributedPensionProjection = Number(userContributedPension)

    const quote: Quote = {
        target,
        projection: statePensionValue + userContributedPensionProjection,
        barChart: [
            {
                incomeGap:
                    target >
                    statePensionValue + userContributedPensionProjection
                        ? target -
                          (statePensionValue + userContributedPensionProjection)
                        : 0,
                userContributedPensionProjection,
                statePensionValue
            }
        ]
    }
    return quote
}

export const getAPIparamaters = (
    age: number,
    income: number,
    contributionValue: number,
    target: number,
    currentPensionValue: number
) => {
    const api: API = {
        type5year: APItype5(age, income, contributionValue, currentPensionValue)
        //type6year: APItype6(year, age, income, target, currentPensionValue),
    }
    return api
}
