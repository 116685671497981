import { CalculatorSteps } from './interfaces'

export const stepGrouping = (
    steps: CalculatorSteps,
    isDesktop: boolean = false
) => {
    if (isDesktop) return steps

    const stepsWithoutGroups: CalculatorSteps = steps.reduce(
        (steps, currentStep) => {
            if (currentStep.type === 'calculatorstepgroup')
                steps.push(...currentStep.steps)
            else steps.push(currentStep)
            return steps
        },
        [] as CalculatorSteps
    )

    return stepsWithoutGroups
}
