import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { Link } from 'common/components/atoms/Link'
import { CardData } from 'common/components/design-system/molecules/CardData'
import {
    CalculatorStepCommon,
    QuoteComponentData,
} from 'common/components/design-system/organisms/CalculatorCms'
import { CollapsableContainer } from 'common/components/design-system/organisms/CollapsableContainer'
import { Spacer } from 'common/components/molecules/Spacer'
import numeral from 'numeral'
import React from 'react'
import TagManager from 'react-gtm-module'
import { CalculatorCards } from '../cards/calculatorCards'
import { getPlans } from './onePlanConfig'
export interface QuoteComponentDataInput {
    term: string
    lifeCoverAmt: number
    jointLife: string
    maritalStatus1: string
    maritalStatus2: string
    age1: number
    age2: number
    smokerCd1: string
    smokerCd2: string
    income1: number
    income2: number
    mortgagePayment: number
    mortgagePayment2: number
    mortgageTerm1: number
    mortgageTerm2: number
    childNum1: number
    youngestChild1: number
    childNum2: number
    youngestChild2: number
    specifiedIllnessConversion1: string
    specifiedIllnessAmt1: number
    specifiedIllnessConversion2: string
    specifiedIllnessAmt2: number
    lifeCoverConversion1: string
    lifeCoverAmt1: number
    lifeCoverConversion2: string
    lifeCoverAmt2: number
    funeralCover1: string
    funeralCover2: string
    incomeProtection: string
    incomeProtection2: string
}

//TODO - Inputs to strings, not numbers
export interface QuoteComponentDataApi {
    fee: number
    levy: number
    prem: number
    premInclLevy: string
    standardQuoteData?: OnePlanQuoteData
    essentialQuoteData?: OnePlanQuoteData
    comprehensiveQuoteData?: OnePlanQuoteData
    error?: boolean
}
export interface OnePlanQuoteData {
    premInclLevy: string
    levy: string
    fee: string
    lifeCoverPrem1: string
    sicCoverPrem1: string
    billPayPrem1: string
    lifeCoverPrem2: string
    sicCoverPrem2: string
    billPayPrem2: string
}
export interface QuoteComponentDataCms {
    refLabel: string
    refNumber: string
    quoteTotal: string
    quoteLabel: string
    regText: string
    ctaUrl: string
    ctaLabel: string
    cardTwoTopLabel: string
    cardTwoBottomLabel: string
    cardTwoBottomContent: string
    productOneLabel: string
    productTwoLabel: string
    productThreeLabel: string
    productFourLabel: string
}

export interface QuoteComponentDataMortgageProtection
    extends QuoteComponentData {
    input: QuoteComponentDataInput
    api: QuoteComponentDataApi
    cms: QuoteComponentDataCms
}
export interface OnePlanProtectionQuoteProps
    extends Partial<CalculatorStepCommon> {
    data: QuoteComponentDataMortgageProtection
    classes: any
    body?: any
    maxWidth?: string
    status?: string
    label: string
    title: string
    isCollapsed?: boolean
    onExpand?: () => void
    onCollapse?: () => void
}

const addValues = (val?: string, val2?: string) => {
    let first = val ? parseFloat(val) : 0,
        second = val2 ? parseFloat(val2) : 0
    let result = first + second
    return result.toFixed(2)
}

export const OnePlanProtectionQuote: React.FC<OnePlanProtectionQuoteProps> = ({
    data: { input, api, cms },
}) => {
    const plans = getPlans(
        input.jointLife,
        input.age1,
        input.mortgageTerm1,
        input.maritalStatus1,
        input.income1,
        input.childNum1,
        input.youngestChild1,
        input.mortgagePayment,
        input.specifiedIllnessAmt1,
        input.lifeCoverConversion1,
        input.lifeCoverAmt1,
        input.age2,
        input.income2,
        input.specifiedIllnessAmt2,
        input.lifeCoverConversion2,
        input.lifeCoverAmt2
    )
    if (input.maritalStatus1 === 'N' && input.childNum1 < 1) {
        input.funeralCover1 = 'Y'
    }
    const combinedIncome = input.income2
        ? Number(input.income1) + Number(input.income2)
        : Number(input.income1)
    // Bill Cover to max of 40% of combined income
    const billCoverAmountMax = combinedIncome * 0.4
    const billCoverAmountTotal = Math.min(
        billCoverAmountMax,
        Number(input.mortgagePayment)
    )
    // Bill Cover to be split between partners in the event of a joint policy
    const billCoverAmtBeforeUtilities =
        input.jointLife === 'Y'
            ? billCoverAmountTotal / 2
            : billCoverAmountTotal
    const billCoverAmount = billCoverAmtBeforeUtilities + 400

    const decreasingLifeCoverNotes =
        'Pays a lump sum if anyone covered on the plan dies. The cover will decrease each year because the amount of replacement income your family needs reduces over time.'
    const billCoverNotes =
        'Pays your essential monthly bills if the person covered cannot work because of an illness or injury while employed. You will not be able to claim if you become unemployed for more than one month and your claim may be limited if living abroad at the time of your claim.'
    const specifiedIllnessCoverNotes =
        'Pays a lump sum if you are diagnosed with one of the illness covered on the plan.'
    const riskAssociatedProps = {
        label: 'RISKS ASSOCIATED WITH THIS QUOTE',
        data: [
            'See cover requirements section below for further detail on the calculation and assumptions for recommended cover levels.',
            'This assessment may result in an increase in the cost of cover. Irish Life Assurance plc is regulated by the Central Bank of Ireland. This indicative quote from Irish Life Financial Services is for a OnePlan protection policy from Irish Life Assurance plc and is subject to further assessment of health status.',
        ],
    }
    const validityProps = {
        label: 'VALIDITY',
        data: 'The quote is valid either for 7 days or to your next birthday, whichever is sooner and is subject to no changes to the applicable regulatory or tax rules within that period. You must keep up payments to stay on cover. This planner gives general guidance but is not individual financial advice. We recommend you discuss your full circumstances and the cover with one of our qualified financial advisors',
    }
    const funeralCoverProps = {
        label: 'Funeral Cover',
        data: input.funeralCover1 === 'Y' ? '€10,000' : 'Not Applicable',
    }
    const comprehensive = [
        {
            Component: CardData,
            id: 1,
            props: {
                label: 'Decreasing life cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.decreasingLifeCover1?.ComprehensivePlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.decreasingLifeCover1?.ComprehensivePlan
                          ).format('0,000')}`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.decreasingLifeCover2?.ComprehensivePlan !== 0
                        ? `€${numeral(
                              plans.decreasingLifeCover2?.ComprehensivePlan
                          ).format('0,000')}`
                        : '',
                    `(100% of the gap in life cover)`,
                    decreasingLifeCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 2,
            props: {
                label: 'Bill Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    input.incomeProtection === 'Y'
                        ? 'Not Applicable'
                        : `€${billCoverAmount} per month up to ${plans.billCover1?.ComprehensivePlan} years`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y'
                        ? input.incomeProtection2 === 'Y'
                            ? 'Not Applicable'
                            : `€${billCoverAmount} per month up to ${plans.billCover2?.ComprehensivePlan} years`
                        : '',
                    billCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 3,
            props: {
                label: 'Specified Illness Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.specifiedIllness1?.ComprehensivePlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.specifiedIllness1?.ComprehensivePlan
                          ).format('0,000')} `,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.specifiedIllness2?.ComprehensivePlan !== 0
                        ? `€${numeral(
                              plans.specifiedIllness2?.ComprehensivePlan
                          ).format('0,000')} `
                        : '',
                    '(Two years net salary)',
                    specifiedIllnessCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 4,
            props: funeralCoverProps,
        },
        {
            Component: CardData,
            id: 5,
            props: {
                label: 'Price Breakdown',
                data: [
                    `Cost of Decreasing Life Cover: €` +
                        addValues(
                            api.comprehensiveQuoteData?.lifeCoverPrem1,
                            api.comprehensiveQuoteData?.lifeCoverPrem2
                        ),
                    `Cost of Bill Cover: €` +
                        addValues(
                            api.comprehensiveQuoteData?.billPayPrem1,
                            api.comprehensiveQuoteData?.billPayPrem2
                        ),
                    `Cost of Illness Cover: €` +
                        addValues(
                            api.comprehensiveQuoteData?.sicCoverPrem1,
                            api.comprehensiveQuoteData?.sicCoverPrem2
                        ),
                    `Irish Life Policy Fee: €${Number(
                        api.comprehensiveQuoteData?.fee
                    ).toFixed(2)}`,
                    `1% Government Levy: €${Number(
                        api.comprehensiveQuoteData?.levy
                    ).toFixed(2)}`,
                    `title Total: €${Number(
                        api.comprehensiveQuoteData?.premInclLevy
                    ).toFixed(2)}`,
                ],
            },
        },
        {
            Component: CardData,
            id: 6,
            props: riskAssociatedProps,
        },
        {
            Component: CardData,
            id: 7,
            props: validityProps,
        },
    ]
    const essential = [
        {
            Component: CardData,
            id: 1,
            props: {
                label: 'Decreasing life cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.decreasingLifeCover1?.EssentialPlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.decreasingLifeCover1?.EssentialPlan
                          ).format('0,000')}`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.decreasingLifeCover2?.EssentialPlan !== 0
                        ? `€${numeral(
                              plans.decreasingLifeCover2?.EssentialPlan
                          ).format('0,000')}`
                        : '',
                    `(75% of the gap in life cover)`,
                    decreasingLifeCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 2,
            props: {
                label: 'Bill Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    input.incomeProtection === 'Y'
                        ? 'Not Applicable'
                        : `€${billCoverAmount} per month up to ${plans.billCover1?.EssentialPlan} years`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y'
                        ? input.incomeProtection2 === 'Y'
                            ? 'Not Applicable'
                            : `€${billCoverAmount} per month up to ${plans.billCover2?.EssentialPlan} years`
                        : '',
                    billCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 3,
            props: {
                label: 'Specified Illness Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.specifiedIllness1?.EssentialPlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.specifiedIllness1.EssentialPlan
                          ).format('0,000')}`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.specifiedIllness2?.EssentialPlan !== 0
                        ? `€${numeral(
                              plans.specifiedIllness2?.EssentialPlan
                          ).format('0,000')}`
                        : '',
                    '(One years net salary)',
                    specifiedIllnessCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 4,
            props: funeralCoverProps,
        },
        {
            Component: CardData,
            id: 5,
            props: {
                label: 'Price Breakdown',
                data: [
                    `Cost of Decreasing Life Cover: €` +
                        addValues(
                            api.essentialQuoteData?.lifeCoverPrem1,
                            api.essentialQuoteData?.lifeCoverPrem2
                        ),
                    `Cost of Bill Cover: €` +
                        addValues(
                            api.essentialQuoteData?.billPayPrem1,
                            api.essentialQuoteData?.billPayPrem2
                        ),
                    `Cost of Illness Cover: €` +
                        addValues(
                            api.essentialQuoteData?.sicCoverPrem1,
                            api.essentialQuoteData?.sicCoverPrem2
                        ),
                    `Irish Life Policy Fee: €${Number(
                        api.essentialQuoteData?.fee
                    ).toFixed(2)}`,
                    `1% Government Levy: €${Number(
                        api.essentialQuoteData?.levy
                    ).toFixed(2)}`,
                    `title Total: €${Number(
                        api.essentialQuoteData?.premInclLevy
                    ).toFixed(2)}`,
                ],
            },
        },
        {
            Component: CardData,
            id: 6,
            props: riskAssociatedProps,
        },
        {
            Component: CardData,
            id: 7,
            props: validityProps,
        },
    ]
    const standard = [
        {
            Component: CardData,
            id: 1,
            props: {
                label: 'Decreasing life cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.decreasingLifeCover1?.StandardPlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.decreasingLifeCover1?.StandardPlan
                          ).format('0,000')}`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.decreasingLifeCover2?.StandardPlan !== 0
                        ? `€${numeral(
                              plans.decreasingLifeCover2?.StandardPlan
                          ).format('0,000')}`
                        : '',
                    `(50% of the gap in life cover)`,
                    decreasingLifeCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 2,
            props: {
                label: 'Bill Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    input.incomeProtection === 'Y'
                        ? 'Not Applicable'
                        : `€${billCoverAmount} per month up to ${plans.billCover1?.StandardPlan} years`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y'
                        ? input.incomeProtection2 === 'Y'
                            ? 'Not Applicable'
                            : `€${billCoverAmount} per month up to ${plans.billCover2?.StandardPlan} years`
                        : '',
                    billCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 3,
            props: {
                label: 'Specified Illness Cover',
                data: [
                    input.jointLife === 'Y' ? 'title Your cover' : '',
                    plans.specifiedIllness1?.StandardPlan === 0
                        ? 'Not Applicable'
                        : `€${numeral(
                              plans.specifiedIllness1.StandardPlan
                          ).format('0,000')}`,
                    input.jointLife === 'Y' ? "title Your partner's cover" : '',
                    input.jointLife === 'Y' &&
                    plans.specifiedIllness2?.StandardPlan !== 0
                        ? `€${numeral(
                              plans.specifiedIllness2?.StandardPlan
                          ).format('0,000')}`
                        : '',
                    '(Six months net salary)',
                    specifiedIllnessCoverNotes,
                ],
            },
        },
        {
            Component: CardData,
            id: 4,
            props: funeralCoverProps,
        },
        {
            Component: CardData,
            id: 5,
            props: {
                label: 'Price Breakdown',
                data: [
                    `Cost of Decreasing Life Cover: €` +
                        addValues(
                            api.standardQuoteData?.lifeCoverPrem1,
                            api.standardQuoteData?.lifeCoverPrem2
                        ),
                    `Cost of Bill Cover: €` +
                        addValues(
                            api.standardQuoteData?.billPayPrem1,
                            api.standardQuoteData?.billPayPrem2
                        ),
                    `Cost of Illness Cover: €` +
                        addValues(
                            api.standardQuoteData?.sicCoverPrem1,
                            api.standardQuoteData?.sicCoverPrem2
                        ),
                    `Irish Life Policy Fee: €${Number(
                        api.standardQuoteData?.fee
                    ).toFixed(2)}`,
                    `1% Government Levy: €${Number(
                        api.standardQuoteData?.levy
                    ).toFixed(2)}`,
                    `title Total: €${Number(
                        api.standardQuoteData?.premInclLevy
                    ).toFixed(2)}`,
                ],
            },
        },
        {
            Component: CardData,
            id: 6,
            props: riskAssociatedProps,
        },
        {
            Component: CardData,
            id: 7,
            props: validityProps,
        },
    ]
    const coverRequirements = [
        {
            Component: CardData,
            id: 1,
            props: {
                label: 'Decreasing Life Cover – Lump sum paid on death',
                data: [
                    `The gap in cover is calculated by taking your net income, minus state benefits and any mortgage payments covered by mortgage protection multiplied by the term.`,
                    `If you have a mortgage, we assume that you have mortgage protection in place. Protection Term is calculated on your youngest child reaching age 25 (if renting) or the remaining term of your mortgage.`,
                    `Monthly state widow benefits of €1,029 plus an additional €199 for each child is assumed. The level of state benefit is dependent on the number of PRSI contributions - we assume 48 or more in this instance.`,
                ],
            },
        },
        {
            Component: CardData,
            id: 2,
            props: {
                label: 'Bill cover – Pays regular amount if unable to work due to injury or illnes',
                data: [
                    `The recommended cover is calculated by adding your monthly mortgage or rent amount, plus €400 for essential bills such as gas, electricity etc.`,
                    `The amount of cover is capped at €2,000 per person or 40% of monthly net income, whichever is lower. Term is based on your youngest child reaching age 25 (if renting) or the remaining term of your mortgage.`,
                ],
            },
        },
        {
            Component: CardData,
            id: 3,
            props: {
                label: 'Specified Illness cover – Lump sum paid on diagnosis of illness',
                data: [
                    "The recommended cover is calculated as two year's net salary. Term is based on your youngest child reaching age 25 or a term of 20 year if no children under age 25. If you are over 65 years of age, you are not eligible for this benefit.",
                ],
            },
        },
    ]
    return (
        <>
            <>
                <CollapsableContainer
                    body={comprehensive}
                    label=''
                    title={[
                        'Comprehensive',
                        `€${Number(
                            api.comprehensiveQuoteData?.premInclLevy
                        ).toFixed(2)}`,
                    ]}
                />
                <Spacer variant='xs' />
                <CollapsableContainer
                    body={essential}
                    label=''
                    title={[
                        'Essential',
                        `€${Number(
                            api.essentialQuoteData?.premInclLevy
                        ).toFixed(2)}`,
                    ]}
                />
                <Spacer variant='xs' />
                <CollapsableContainer
                    body={standard}
                    label=''
                    title={[
                        'Standard',
                        `€${Number(api.standardQuoteData?.premInclLevy).toFixed(
                            2
                        )}`,
                    ]}
                />
                <Spacer variant='xs' />
                <CollapsableContainer
                    body={coverRequirements}
                    label=''
                    title={['Cover Requirements']}
                />
                <Spacer variant='xs' />
                <>
                    <CalculatorCards />
                    <Spacer variant='xs' />
                    <Link
                        onClick={() => {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: 'secondaryButtonClick',
                                    clickText: 'Get a quote in 5 minutes',
                                    clickURL: '',
                                    clickID: 'Get a quote in 5 minutes',
                                },
                            })
                            window.location.reload()
                        }}
                        path={'/life-insurance/one-plan-protection/quote'}
                    >
                        <Button
                            marginLeft={'12px'}
                            rightIcon={undefined}
                            variant='outline'
                        >
                            Restart Quote
                        </Button>
                    </Link>
                    <Spacer variant='xs' />
                </>
            </>
        </>
    )
}
