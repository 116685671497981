const retirementAge: number = 66

export interface PensionQuote {
    year: Quote
    month: Quote
    user: User
}

export interface Quote {
    target: number
    projection: number
    barChart: [
        {
            statePensionValue: number
            incomeGap: number
            userContributedPensionProjection: number
        }
    ]
}

export interface User {
    age: number
    income: number
    statePension: number
    startPensionAge: number
    currentPensionValue: number
    salaryContribution: number
    employerContribution: number
    yearlyPaymentTargetValue: number
}

export interface QuoteComponentDataInput {
    age1: number
    salary: number
    statePension: string
    contributionStart: number
    currentPensionVal: number
    employeePercentageContribution: number
    employeeSalaryContribution: number
    employerPercentageContribution: number
    employerSalaryContribution: number
    monthlyPaymentTargetPercentage: number
    yearlyRetirementIncome: number
    targetSalaryContribution: number
    targetPercentageContribution: number
    corporatePension: string
    currentPension: string
}

export interface API {
    type5year: string
    //Not sure if this API call for type=6 is needed
    //type6year: string
}

export interface APItype5Response {
    value: number
    valueToday: number
    annualPension: number
}

export interface APItype6Response {
    premium: number
    fundToday: number
}

export const APItype5 = (
    age: number,
    income: number,
    contributionValue: number,
    currentPension: number
) => {
    return (
        'type=5&age=' +
        age +
        '&income=' +
        income +
        '&ret=' +
        retirementAge +
        '&ap=' +
        contributionValue +
        '&sp=' +
        (currentPension >= 0 ? currentPension : 0)
    )
}

export const APItype6 = (
    freq: string,
    age: number,
    income: number,
    target: number,
    currentPension: number
) => {
    return (
        'type=6&freq=' +
        freq +
        '&age=' +
        age +
        '&income=' +
        income +
        '&ret=' +
        retirementAge +
        '&target=' +
        target +
        '&sp=' +
        (currentPension >= 0 ? currentPension : 0)
    )
}
