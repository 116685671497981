import {
    CalculatorStep,
    CalculatorSteps,
    CalculatorStepWithData,
    instanceOfCalculatorStepGroup
} from 'common/components/design-system/organisms/CalculatorCms'

export const modifySteps = (
    steps: CalculatorSteps,
    fieldName: string,
    value: any
) => {
    return steps.map(step => {
        if ((step as CalculatorStepWithData).fieldName === fieldName) {
            return modifyStep(step, fieldName, value)
        }
        if (instanceOfCalculatorStepGroup(step)) {
            return {
                ...step,
                steps: step.steps.map(step => {
                    if (
                        (step as CalculatorStepWithData).fieldName === fieldName
                    ) {
                        return modifyStep(step, fieldName, value)
                    }
                    return step
                })
            }
        }
        return step
    })
}

export const modifyStep = (
    step: CalculatorStep,
    fieldName: string,
    value: any
) => {
    if ((step as CalculatorStepWithData).fieldName === fieldName) {
        return { ...step, ...value }
    }
    return step
}
