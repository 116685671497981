import axios from 'axios'
import qs from 'qs'

export const APIrequest = async (
    URL: string,
    paramaters: string,
) => {
    const [request] = await Promise.all([
        axios.get(`${URL}?${paramaters}`),
    ])

    let quoteData
    let data
    if (request.data === 'Error') {
        data = { error: true }
    } else {
        quoteData = qs.parse(
            request.data.replace('var result = ', '').replace(';','').replaceAll("'", '')
        )
        data = {
            quoteData,
            error: false
        }
    }
    return data
}