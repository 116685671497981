import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { Caption } from '../../atoms/Caption'
import { SmallTitle } from '../../atoms/SmallTitle'
import { theme } from '../../theme'
import { Tooltip } from '../Tooltip'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CardContainerProps {
    classes?: any
    maxWidth?: string
    body?: any
    label?: string
    title?: string
    content?: any
    onMouseEnter?: () => void
    onMouseLeave?: () => void
    tooltip?: {
        title?: string
        content?: string
    }
    bottomSpace?: boolean
    small?: boolean
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        backgroundColor: palette.WHITE,
        padding: '32px 24px 40px',
        borderRadius: '16px',
        boxShadow: '4px 0px 24px rgba(82, 97, 172, 0.08);',
        minHeight: '214px',
        maxWidth: 'calc(100vw - 48px)',
        fontFamily: '"Assistant", sans-serif',
        position: 'relative',
    },
    small: {
        width: '100%',
        minHeight: '54px',
        padding: '17px 16px'
    },
    bottomSpace: {
        paddingBottom: '100px'
    },
    tooltipCard: {
        minHeight: '0px'
    },
    label: {
        fontWeight: 700,
        fontSize: '12px',
        textTransform: 'uppercase',
        color: palette.BRAND_400,
        lineHeight: '100%',
        margin: '0px'
    },
    title: {
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '120%',
        color: palette.NEUTRAL_900,
        margin: '7px 0px'
    },
    section: {
        margin: '24px 0px'
    },
    content: {
        fontWeight: 500,
        fontFamily: '"Assistant", sans-serif',
        fontSize: '18px',
        lineHeight: '28px',
        color: palette.NEUTRAL_700,
        marginTop: '40px'
    },
    centreComp: {
        display: 'flex',
        justifyContent: 'center'
    }
}

export const CardContainerElem: React.FC<CardContainerProps> = ({
    ...props
}) => {
    const {
        classes,
        maxWidth,
        label,
        title,
        body,
        content,
        tooltip,
        bottomSpace,
        small
    } = props
    const maximumWidth = maxWidth ? `${maxWidth}px` : ''
    const createMarkup = (content: string) => {
        return { __html: content }
    }

    return (
        <div
            className={`${classes.root} ${bottomSpace &&
                classes.bottomSpace} ${small && classes.small}`}
            style={{ maxWidth: maximumWidth }}
        >
            {tooltip && (
                <Tooltip
                    title={tooltip.title}
                    content={tooltip.content}
                    alignRight={true}
                />
            )}
            {label && <Caption text={label} />}

            {title && <SmallTitle text={title}></SmallTitle>}

            {body && (
                <div>
                    {body.map(
                        (
                            { Component, props, id, html, position }: any,
                            index: number
                        ) => (
                            <div
                                key={id}
                                className={`${
                                    small
                                        ? index !== 0 && classes.section
                                        : classes.section
                                } ${position === 'center' &&
                                    classes.centreComp}`}
                            >
                                {Component && <Component {...props} />}
                                {html && (
                                    <div
                                        dangerouslySetInnerHTML={createMarkup(
                                            html
                                        )}
                                    ></div>
                                )}
                            </div>
                        )
                    )}
                </div>
            )}
            {content && (
                <div
                    className={classes.content}
                    dangerouslySetInnerHTML={createMarkup(content)}
                ></div>
            )}
        </div>
    )
}

export const CardContainer = withStyles(styles)(CardContainerElem)
