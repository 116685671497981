import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useState, useEffect } from 'react'
import { CardContainer } from '../cardContainer'
import { CardHeader } from '../../molecules/CardHeader'
import TIMES_SMALL from './assets/times-small.svg'
import ARROW_DOWN from './assets/arrow-down-small-900.svg'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CollapsableContainerProps {
    classes: any
    body?: any
    maxWidth?: string
    status?: string
    label: string
    title: string | string[]
    isCollapsed?: boolean
    onExpand?: () => void
    onCollapse?: () => void
}

const styles: Styles<Theme, {}, string> = {
    root: {
        position: 'relative'
    },
    collapseIcon: {
        position: 'absolute',
        top: '20px',
        right: '50px',
        zIndex: 10,
        cursor: 'pointer'
    },
    collapsed: {
        cursor: 'pointer'
    }
}

export const CollapsableContainerElem: React.FC<CollapsableContainerProps> = ({
    ...props
}) => {
    const {
        classes,
        body,
        maxWidth,
        status,
        label,
        title,
        isCollapsed,
        onExpand,
        onCollapse
    } = props
    const header = [
        {
            Component: CardHeader,
            id: 0,
            props: {
                label,
                title,
                status
            }
        }
    ]
    const [collapsed, setCollapsed] = useState(
        isCollapsed !== null && isCollapsed !== undefined ? isCollapsed : true
    )
    useEffect(() => {
        const coll =
            isCollapsed !== null && isCollapsed !== undefined
                ? isCollapsed
                : true
        setCollapsed(coll)
    }, [isCollapsed])
    const fullBody = body && body !== undefined ? [...header, ...body] : header
    const handleOpen = () => {
        collapsed && setCollapsed(false)
        onExpand && onExpand()
    }

    const handleClose = () => {
        setCollapsed(true)
        onCollapse && onCollapse()
    }

    return (
        <div style={{ maxWidth: `${maxWidth}px` }} className={classes.root}>
            {!collapsed && (
                <img
                    src={TIMES_SMALL}
                    alt='Collapse Container'
                    onClick={handleClose}
                    className={classes.collapseIcon}
                />
            )}
            {collapsed && (
                <img
                    src={ARROW_DOWN}
                    alt='Collapse Container'
                    onClick={handleOpen}
                    className={classes.collapseIcon}
                />
            )}
            <div
                onClick={handleOpen}
                className={collapsed && classes.collapsed}
            >
                <CardContainer
                    body={collapsed ? header : fullBody}
                    small={true}
                />
            </div>
        </div>
    )
}

export const CollapsableContainer = withStyles(styles)(CollapsableContainerElem)
