import { CalculatorSteps } from 'common/components/design-system/organisms/CalculatorCms/interfaces'
import { lifeLongProtection } from './lifeLongProtection'
import { mortgageProtection } from './mortgageProtection'
import { onePlan } from './onePlan'
import { pensionsTool } from './pensions'
import { termLifeProtection } from './termLifeProtection'
import { termLifeProtectionCopy } from './termLifeProtectionCopy'

/**
 * Calculator configuration
 *
 * @export
 * @interface CalculatorConfig
 */
export interface CalculatorConfig {
    getQuoteData: any
    initialData?: any
    stepsTransformation: (steps: CalculatorSteps, data: any) => CalculatorSteps
    onStepChange?: (
        step: number,
        steps: CalculatorSteps,
        data: any,
        source?: string
    ) => void
    quoteComponent: React.FC<{ data: QuoteComponentData }>
    icon: string
    toolType?: string
    label?: string
    createCrmLead?: (data: any, calcType: any) => Promise<any>
}

/**
 * Data being injected into the quote component
 *
 * @export
 * @interface QuoteComponentData
 */
export interface QuoteComponentData {
    api: any
    cms: any
    input: any
}

/**
 * Quote configuration
 */
export interface QuoteComponentDataInput {
    term: string
    lifeCoverAmt: number
    jointLife: string
}
export interface QuoteComponentDataApi {
    calcType: string
    fee: number
    levy: number
    prem: number
    premInclLevy: string
}
export interface QuoteComponentDataCms {
    refLabel: string
    refNumber: string
    quoteTotal: string
    quoteLabel: string
    regText: string
    ctaUrl: string
    ctaLabel: string
    cardTwoTopLabel: string
    cardTwoBottomLabel: string
    cardTwoBottomContent: string
    pensionsTitle: string
    pensionsSubtitle: string
    pensionsSalaryPercent: string
    pensionsAnnualTotal: string
    legendLabel1: string
    legendLabel2: string
    legendLabel3: string
    alert0: string
    alert1: string
    contactFormData: {
        noteDescription: string
        regLines: string
        pickTimeSlot: string
        title: string
        subtitle: string
        firstLabel: string
        secondLabel: string
        description: string
    }
    dropdownBoxes: {
        assumptions: string
        taxRelief: string
    }
}

export const config: { [key: string]: CalculatorConfig } = {
    'life-long-protection': lifeLongProtection,
    'one-plan': onePlan,
    'mortgage-protection': mortgageProtection,
    'term-life-protection': termLifeProtection,
    'term-life-copy': termLifeProtectionCopy,
    pensions: pensionsTool,
}
