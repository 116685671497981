import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import React from 'react'
import { PhoneInput } from '../../../../molecules/phoneInput'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepPhoneNumber as CalculatorStepPhoneNumberInterface } from '../../interfaces'

export interface CalculatorStepPhoneNumberProps
    extends CalculatorStepPhoneNumberInterface {
    updateData: (newData: { [key: string]: any }) => void
}

export const CalculatorStepPhoneNumber: React.FC<CalculatorStepPhoneNumberProps> = ({
    data = {},
    fieldName,
    group,
    title,
    tooltip,
    options = [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
    ],
    placeholder,
    updateData
}) => {

    const update = (value: string | number) => {
        updateData({ [fieldName]: value })
    }

    const usePhoneStyles = makeStyles({
        root: {
            width: '100%'
        }
    })

    const phoneClasses = usePhoneStyles()

    return (
        <>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}


            <Box alignItems='center'>
                <PhoneInput
                    classes={phoneClasses}
                    placeholder={placeholder}
                    onBlur={update}
                />
            </Box>

        </>
    )
}
