import { Spacer } from 'common/components/molecules/Spacer'
import _ from 'lodash'
import React from 'react'
import { CalculatorStep as CalculatorStepInterface } from '../../interfaces'
import { CalculatorStepBinaryChoice } from '../CalculatorStepBinaryChoice'
import { CalculatorStepChart } from '../CalculatorStepChart'
import { CalculatorStepCompound } from '../CalculatorStepCompound/CalculatorStepCompound'
import { CalculatorStepContents } from '../CalculatorStepContents'
import { CalculatorStepDropdown } from '../CalculatorStepDropdown'
import { CalculatorStepEmail } from '../CalculatorStepEmail/CalculatorStepEmail'
import { CalculatorStepGroup } from '../CalculatorStepGroup'
import { CalculatorStepInput } from '../CalculatorStepInput'
import { CalculatorStepList } from '../CalculatorStepList'
import { CalculatorStepMobileGroup } from '../CalculatorStepMobileGroup'
import { CalculatorStepPhoneNumber } from '../CalculatorStepPhoneNumber/CalculatorStepPhoneNumber'
import { CalculatorStepSlider } from '../CalculatorStepSlider'
import { CalculatorStepText } from '../CalculatorStepText'

export const CalculatorStep: React.FC<CalculatorStepInterface> = ({
    caption,
    ...props
}) => {
    switch (props.type) {
        case 'calculatorstepbinarychoice':
            return (
                <CalculatorStepBinaryChoice
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepchart':
            return (
                <CalculatorStepChart
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepcontents':
            return (
                <CalculatorStepContents
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorsteplist':
            return (
                <CalculatorStepList
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepslider':
            return (
                <CalculatorStepSlider
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepinput':
            return (
                <CalculatorStepInput
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepdropdown':
            return (
                <CalculatorStepDropdown
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepgroup':
            return (
                <>
                    <Spacer variant='xs' />
                    <CalculatorStepGroup
                        {...props}
                        updateData={props.updateData || _.noop}
                    />
                </>
            )
        case 'calculatormobilestepgroup':
            return (
                <>
                    <Spacer variant='xs' />
                    <CalculatorStepMobileGroup
                        {...props}
                        updateData={props.updateData || _.noop}
                    />
                </>
            )
        case 'calculatorstepphonenumber':
            return (
                <CalculatorStepPhoneNumber
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepemail':
            return (
                <CalculatorStepEmail
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorstepcompound':
            return (
                <CalculatorStepCompound
                    {...props}
                    updateData={props.updateData || _.noop}
                />
            )
        case 'calculatorsteptext':
        default:
            return <CalculatorStepText {...props} />
    }
}
