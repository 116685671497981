import React, { useState } from 'react'
import plusWhite from './plus-white.svg'
import minusWhite from './minus-white.svg'
import { makeStyles } from '@material-ui/core'

export interface CounterProps {
    maxValue: number
    minValue: number
    initialValue?: number
    handleToggle?: any
}

export const Counter: React.FC<CounterProps> = ({maxValue, minValue, initialValue, handleToggle }: CounterProps) => {
    const [count, setCount] = useState(() => {
        return initialValue && initialValue >= 0 ? initialValue : 0
    })
    function decrementCount() {
        if(count > minValue){
            setCount(count - 1)
            handleToggle(count - 1)
        } else {
            setCount(minValue)
            handleToggle(minValue)
        }
    }
    function incrementCount() {
        if(count < (maxValue - 1)){
            setCount(Number(count) + 1)
            handleToggle(Number(count) + 1)
        } else {
            setCount(maxValue)
            handleToggle(maxValue)
        }
    }

    const useStyles = makeStyles({
        decrementButton: {
            width: '48px',
            height: '48px',
            background: '#F1F3FD',
            borderRadius: '24px 0px 0px 24px',
            border: 'none',
            transition: '0.3s',
            '&:hover': {
                backgroundColor: '#717FE2'
            }
        },
        incrementButton: {
            width: '48px',
            height: '48px',
            background: '#F1F3FD',
            borderRadius: '0px 24px 24px 0px',
            border: 'none',
            transition: '0.3s',
            '&:hover': {
                backgroundColor: '#717FE2'
            }
        },
        counterBox: {
            width: '64px',
            height: '48px',
            background: '#EBEDF7',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#99A2CD',
            fontSize: '16px',
            margin: '0px 2px'
        }
    })
    const classes = useStyles()
    return (
        <div data-testid='Counter' style={{ display: 'flex' }}>
            <button
                data-testid='counterDecrementBtn'
                onClick={decrementCount}
                className={classes.decrementButton}
            >
                <img alt='minus-white' src={minusWhite} />
            </button>
            <div onChange={handleToggle} className={classes.counterBox}>{count}%</div>
            <button
                data-testid='counterIncrementBtn'
                onClick={incrementCount}
                className={classes.incrementButton}
            >
                <img alt='plus-white' src={plusWhite} />
            </button>
        </div>
    )
}
