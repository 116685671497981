import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ProgressNumbersProps {
    classes: any
    total?: number
    current?: number
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        color: palette.BRAND_300_SECONDARY,
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 'bold',
        fontFamily: '"Assistant", sans-serif',
        margin: '0px'
    },
    current: {
        color: palette.NEUTRAL_900
    }
}

export const ProgressNumbersElem: React.FC<ProgressNumbersProps> = ({
    ...props
}) => {
    const { current, total, classes } = props
    return (
        <p className={classes.root}>
            <span className={classes.current}>{current}</span>/{total}
        </p>
    )
}

export const ProgressNumbers = withStyles(styles)(ProgressNumbersElem)
