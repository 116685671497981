import React from 'react'

/**
 * Common steps for Calculator
 *
 * @export
 * @interface CalculatorStepCommon
 */
export interface CalculatorStepCommon {
    codename: string
    data?: any
    group?: boolean
    label: string
    title: string
    type?: string
    toolCaption?: string
    caption?: string
    cardCaption?: string
    tooltip?: {
        title?: string
        content?: string
    }
    updateData?: (newData: { [key: string]: any }) => void
}

/**
 * Calculator step with data captured
 *
 * @export
 * @interface CalculatorStepWithData
 */
export interface CalculatorStepWithData {
    fieldName: string
}

export const instanceOfCalculatorStepWithData = (
    object: any
): object is CalculatorStepWithData => {
    return 'fieldName' in object
}

/**
 * Calculator step with a text field
 *
 * @export
 * @interface CalculatorStepText
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepText extends CalculatorStepCommon {
    type: 'calculatorsteptext'
    content: string
    //variant: string
}
/**
 * Calculator step with a list field
 *
 * @export
 * @interface CalculatorStepList
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepList extends CalculatorStepCommon {
    type: 'calculatorsteplist'
    content: string
    variant: string
    listText: string
    listIcon: string
    stepper: number
    benefits?: { listTitle: string; text: string; type: string }[]
}
/**
 * Calculator step with a contents field
 *
 * @export
 * @interface CalculatorStepContents
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepContents extends CalculatorStepCommon {
    type: 'calculatorstepcontents'
    contentHeading: string
    contents?: {
        content__title: string
        content__icon: string
        content__step_number: number
    }[]
}
/**
 * Calculator step with a chart field
 *
 * @export
 * @interface CalculatorStepChart
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepChart extends CalculatorStepCommon {
    type: 'calculatorstepchart'
    text: string
    title: string
    needsTitle: string
    coverTitle: string
    chartText: string
}
/**
 *  Calculator step with a binary choice
 *
 * @export
 * @interface CalculatorStepBinaryChoice
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepBinaryChoice
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    type: 'calculatorstepbinarychoice'
    options?: { label: string; value?: string }[]
}

/**
 *  Calculator step with Slider
 *
 * @export
 * @interface CalculatorStepSlider
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepSlider
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    format?: string
    type: 'calculatorstepslider'
    max: number
    min: number
    step?: number
}

export const instanceOfCalculatorStepSlider = (
    object: any
): object is CalculatorStepSlider => {
    return 'max' in object
}

/**
 *  Calculator step with input field
 *
 * @export
 * @interface CalculatorStepInput
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepInput
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    isValid: boolean
    type: 'calculatorstepinput'
    value: string
    placeholder: string
    step?: number
    text: string
    inputType: string
    fieldLabel?: string
    fieldHelperText?: string
    fieldOptional?: boolean
}
/**
 *  Calculator step with dropdown field
 *
 * @export
 * @interface CalculatorStepDropdown
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepDropdown
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    isValid: boolean
    type: 'calculatorstepdropdown'
    value: string
    placeholder: string
    step?: number
    text: string
    options?: { label: string; value?: string }[]
}

/**
 *  Calculator steps group for desktop
 *
 * @export
 * @interface CalculatorStepGroup
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepGroup
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    type: 'calculatorstepgroup'
    steps: CalculatorSteps
    step?: number
}

/**
 *  Calculator steps group for mobile
 *
 * @export
 * @interface CalculatorStepMobileGroup
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepMobileGroup
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    type: 'calculatormobilestepgroup'
    steps: CalculatorSteps
    step?: number
}

export const instanceOfCalculatorStepGroup = (
    object: any
): object is CalculatorStepGroup => {
    return 'steps' in object
}

/**
 *  Calculator step with phone number input field
 *
 * @export
 * @interface CalculatorStepPhoneNumber
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepPhoneNumber
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    placeholder: string
    options?: { label: string; value?: string }[]
    type: 'calculatorstepphonenumber'
    value: string
    step?: number
}

/**
 *  Calculator step with email input field
 *
 * @export
 * @interface CalculatorStepEmail
 * @extends {CalculatorStepCommon}
 */
export interface CalculatorStepEmail
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    placeholder: string
    options?: { label: string; value?: string }[]
    type: 'calculatorstepemail'
    value: string
    step?: number
}

export interface CalculatorStepCompound
    extends CalculatorStepCommon,
        CalculatorStepWithData {
    type: 'calculatorstepcompound'
    conditional_component: any
    contingent_components: any
    test_condition: string
    evaluationFunction: any
}

/**
 * Calculator step
 */
export type CalculatorStep =
    | CalculatorStepText
    | CalculatorStepChart
    | CalculatorStepContents
    | CalculatorStepList
    | CalculatorStepBinaryChoice
    | CalculatorStepSlider
    | CalculatorStepInput
    | CalculatorStepPhoneNumber
    | CalculatorStepEmail
    | CalculatorStepGroup
    | CalculatorStepMobileGroup
    | CalculatorStepDropdown
    | CalculatorStepCompound

// export type CalculatorSteps = CalculatorStep[]
export type CalculatorSteps = CalculatorStep[]

/**
 * Calculator container
 *
 * @export
 * @interface Calculator
 */
export interface Calculator {
    buttons?: {
        previous?: boolean
        reset?: boolean
    }
    config: CalculatorConfig
    steps: CalculatorSteps
    quote: any
    email: { from: string; html: string; subject: string }
    toolLabel?: string
    toolCaption?: string
    CalculatorType?: string
}
/**
 * Data being injected into the quote component
 *
 * @export
 * @interface QuoteComponentData
 */
export interface QuoteComponentData {
    api: any
    cms: any
    input: any
}
/**
 * Calculator configuration
 *
 * @export
 * @interface CalculatorConfig
 */
export interface CalculatorConfig {
    getQuoteData: any
    initialData?: any
    stepsTransformation?: (steps: CalculatorSteps, data: any) => CalculatorSteps
    onStepChange?: (step: number, steps: CalculatorSteps, data: any) => void
    quoteComponent: React.FC<{ data: QuoteComponentData }>
    icon: string
    toolType?: string
    label?: string
    createCrmLead?: (data: any, calcType: any) => Promise<any>
}
