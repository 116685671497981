import {
    COUNTRY_CODE_IRELAND,
    COUNTRY_CODE_UK,
    IRELAND,
    LANDLINE
} from './constants'
import { PREFIXES } from './phonePrefixes'

export const formatNumber = (number: any) => {
    let formattedNumber = [...number]
    return `${formattedNumber.slice(0, 3).join('')} ${formattedNumber
        .slice(3, formattedNumber.length)
        .join('')}`
}

export const handleNumber = ({
    pre,
    phoneNumber,
    countryCode,
    country
}: {
    pre: string
    phoneNumber: string
    countryCode: string
    country: string
}) => {
    const prefix = pre.replace('0', '')
    phoneNumber = phoneNumber.replace(pre, '')
    const formattedNumber = formatNumber(phoneNumber)
    return {
        countryCode,
        country,
        type: LANDLINE,
        prefix,
        phoneNumber: formattedNumber
    }
}

export const matchCountry = (value: any, country: string) => {
    const countryCode =
        country === IRELAND ? COUNTRY_CODE_IRELAND : COUNTRY_CODE_UK
    let phoneNumber = value.replace(/\s+/g, '')
    // let prefix = null
    const matchCountry = PREFIXES[country].COUNTRY_CODES.filter(
        (countryCode: string) => {
            return phoneNumber.startsWith(countryCode)
        }
    )
    if (matchCountry.length > 0) {
        phoneNumber = `0${phoneNumber.replace(matchCountry[0], '')}`
    }
    const matchMobile = PREFIXES[country].MOBILE.filter((prefix: string) =>
        phoneNumber.startsWith(prefix)
    )
    if (matchMobile.length > 0)
        return handleNumber({
            pre: matchMobile[0],
            phoneNumber,
            countryCode,
            country
        })
    const matchAreaCode = PREFIXES[
        country
    ].AREA_CODES.filter((prefix: string) => phoneNumber.startsWith(prefix))
    if (matchAreaCode.length > 0)
        return handleNumber({
            pre: matchAreaCode[0],
            phoneNumber,
            countryCode,
            country
        })
    return null
}
