import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CaptionProps {
    classes: any
    text?: string
    color?: string
    fontSize?: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        fontFamily: 'Assistant',
        fontWeight: 700,
        fontSize: '12px',
        textTransform: 'uppercase',
        color: palette.BRAND_400,
        lineHeight: '100%',
        margin: '0px'
    }
}

export const CaptionElem: React.FC<CaptionProps> = ({ ...props }) => {
    const { classes, text, color, fontSize } = props
    return (
        <p
            className={classes.root}
            style={{ color: color || undefined, fontSize: fontSize }}
        >
            {text}
        </p>
    )
}

export const Caption = withStyles(styles)(CaptionElem)
