import { UMBRELLA_CIRCLE } from 'common/components/design-system/assets/icons'
import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import { createCrmLead } from 'common/services/crm/createCrmLead'
import { OnePlanProtectionQuote } from './OnePlanProtectionQuote'
import {
    SICTransformation,
    billPayout,
    billTransformation,
    children2Transformation,
    childrenTransformation,
    configureCalculatorQuoteData,
    consultationTransformation,
    covidTransformation,
    funeralTransformation,
    lifeCover2Transformation,
    lifeCoverTransformation,
    lifeTransformation,
    mortgageTransformation,
    onePlanJointTransformation,
    rent2Transformation,
    rentTransformation,
    specifiedIllness2Transformation,
    specifiedIllnessTransformation,
} from './configFunctions'

export const onePlan: CalculatorConfig = {
    getQuoteData: configureCalculatorQuoteData('one-plan'),

    icon: UMBRELLA_CIRCLE,
    quoteComponent:
        OnePlanProtectionQuote as CalculatorConfig['quoteComponent'],
    stepsTransformation: (steps, data) => {
        const firstSteps = mortgageTransformation(steps, data)
        const secondSteps = consultationTransformation(firstSteps, data)
        const thirdSteps = lifeCoverTransformation(secondSteps, data)
        const fourthSteps = specifiedIllnessTransformation(thirdSteps, data)
        const fifthSteps = childrenTransformation(fourthSteps, data)
        const sixthSteps = rentTransformation(fifthSteps, data)
        const seventhSteps = lifeTransformation(sixthSteps, data)
        const eighthSteps = billTransformation(seventhSteps, data)
        const ninthSteps = onePlanJointTransformation(eighthSteps, data)
        const tenthSteps = children2Transformation(ninthSteps, data)
        const stepEleven = billPayout(tenthSteps, data)
        const stepTwelve = covidTransformation(stepEleven, data)
        const stepThirteen = funeralTransformation(stepTwelve, data)
        const step14 = lifeCover2Transformation(stepThirteen, data)
        const step15 = specifiedIllness2Transformation(step14, data)
        const step16 = rent2Transformation(step15, data)
        return SICTransformation(step16, data)
    },
    createCrmLead: (data) => createCrmLead(data, 'One Plan'),
}
