import React from 'react'
import './switch.css'

export interface SwitchProps {
    isOn?: any
    handleToggle?: any
    onColor?: any
}

export const Switch = ({ isOn, handleToggle, onColor }: SwitchProps) => {
    return (
        <label data-testid='switch' style={{ background: isOn && onColor }} className='react-switch'>
            <input
                checked={isOn}
                onChange={handleToggle}
                className='react-switch-checkbox'
                type='checkbox'
            />
            <div className='react-switch-button' />
            <div className='react-switch-labels'>
                <span style={{ color: !isOn ? '#fff' : '#0C1E68' }}>
                    Yearly
                </span>
                <span style={{ color: isOn ? '#fff' : '#0C1E68' }}>
                    Monthly
                </span>
            </div>
        </label>
    )
}
