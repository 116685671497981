import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import {
    CalculatorStep,
    CalculatorSteps,
    CalculatorStepWithData,
    instanceOfCalculatorStepWithData,
} from 'common/components/design-system/organisms/CalculatorCms/'
// import { occupations } from 'common/components/design-system/organisms/CalculatorCms/steps/CalculatorStepDropdown/occupations'
import { theme } from 'common/components/design-system/theme'
import { ZenDeskAnonymousTicket } from 'common/components/organisms/Contact/ContactLeft/ContactLeftFields'
import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import { API_URL } from 'core'
import qs from 'qs'
import TagManager from 'react-gtm-module'
import { createAnonymousTicket } from '../../../../hooks/useZendeskData'
import { modifySteps } from '../utils/modifySteps'
// import { getOnePlanParams } from './onePlanConfig'

const { palette } = theme

const win =
    typeof window !== 'undefined'
        ? window.matchMedia('(min-width: 1024px)')
        : null

export const getQuoteStyles = () => {
    return makeStyles({
        card: {
            backgroundColor: palette.WHITE,
            padding: '32px 24px 40px',
            borderRadius: '16px',
            boxShadow: '4px 0px 24px rgba(82, 97, 172, 0.08);',
            position: 'relative',
            marginBottom: '40px',
        },
        content: {
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '24px',
            color: palette.NEUTRAL_700,
            marginTop: '8px',
        },
        box: {
            marginBottom: '24px',
        },
        icon: {
            margin: '0px 16px',
        },
        section: {
            fontWeight: 'normal',
            fontSize: '24px',
            lineHeight: '120%',
            color: palette.NEUTRAL_700,
            margin: '0px',
        },
        detailsLabels: {
            color: '#99A2CD',
            fontSize: '14px',
        },
        divider: {
            backgroundColor: '#EBEDF7',
            width: '100%',
            height: '1px',
            margin: '24px 0px',
        },
        title: {
            fontWeight: 500,
            fontSize: '24px',
            lineHeight: '31.2px',
            color: '#4E4670',
        },
        subtitle: {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '31.2px',
            color: '#4E4670',
        },
        caption: {
            fontWeight: 700,
            fontSize: '16px',
            color: '#99A2CD',
        },
        legendLabel: {
            fontWeight: 700,
            fontSize: '12px',
            color: '#4E4670',
        },
        legendValue: {
            fontWeight: 700,
            fontSize: '24px',
            color: '#4E4670',
        },
    })
}

const transformSteps = (steps: any, stepFilter: any) => {
    return steps.reduce((reducedSteps: any, currentStep: any) => {
        if (currentStep.type === 'calculatorstepgroup') {
            const filteredGroups = currentStep.steps.filter(stepFilter)

            reducedSteps.push({ ...currentStep, steps: filteredGroups })
        } else if (stepFilter(currentStep)) reducedSteps.push(currentStep)
        return reducedSteps
    }, [] as CalculatorSteps)
}

export const jointApplicationTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps

        // additional questions when Joint Application
        if (data.jointLife !== 'TRUE') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['age2', 'smokerCd2', 'mortgageCleared'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = steps.reduce((reducedSteps, currentStep) => {
                if (currentStep.type === 'calculatorstepgroup') {
                    const filteredGroupSteps =
                        currentStep.steps.filter(stepFilter)

                    reducedSteps.push({
                        ...currentStep,
                        steps: filteredGroupSteps,
                    })
                } else if (stepFilter(currentStep))
                    reducedSteps.push(currentStep)
                return reducedSteps
            }, [] as CalculatorSteps)
        }
        return transformedSteps
    }

export const consultationTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps

        // additional questions when consultation affirmed
        if (data.consultationAffirmed !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['userName'].includes(step.fieldName)
                return true
            }
            transformedSteps = steps.reduce((reducedSteps, currentStep) => {
                if (currentStep.type === 'calculatorstepgroup') {
                    const filteredGroups = currentStep.steps.filter(stepFilter)

                    reducedSteps.push({ ...currentStep, steps: filteredGroups })
                } else if (stepFilter(currentStep))
                    reducedSteps.push(currentStep)
                return reducedSteps
            }, [] as CalculatorSteps)
        }
        return transformedSteps
    }

export const maxAgeTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps

    const maxAge = 85 - +data.term
    const ageFields = ['age1', 'age2']

    transformedSteps = modifySteps(transformedSteps, ageFields[0], {
        max: maxAge,
    })

    transformedSteps = modifySteps(transformedSteps, ageFields[1], {
        max: maxAge,
    })

    return transformedSteps
}

export const mortgageTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (!data.mortgage) {
        const stepFilter = (step: CalculatorStep) => {
            if (instanceOfCalculatorStepWithData(step))
                return !['mortgagePayment'].includes(step.fieldName)
            return true
        }
        transformedSteps = transformSteps(steps, stepFilter)
    }
    return transformedSteps
}

export const rentTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.mortgage !== 'mortgage') {
        const stepFilter = (step: CalculatorStep) => {
            if (instanceOfCalculatorStepWithData(step))
                return ![
                    'mortgageTerm1',
                    'mortgageCleared',
                    'mortgageCleared2',
                ].includes(step.fieldName)
            return true
        }
        transformedSteps = transformSteps(steps, stepFilter)
    }
    return transformedSteps
}

export const rent2Transformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.mortgage2 !== 'mortgage2') {
        const stepFilter = (step: CalculatorStep) => {
            if (instanceOfCalculatorStepWithData(step))
                return !['mortgageTerm2'].includes(step.fieldName)
            return true
        }
        transformedSteps = transformSteps(steps, stepFilter)
    }
    return transformedSteps
}
export const onePlanJointTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.jointLife === 'N') {
            if (win?.matches) {
                transformedSteps = steps.filter(
                    (step) =>
                        step.codename !== 'group_2___one_plan' &&
                        step.codename !== 'group_3___one_plan___2'
                )
            } else {
                const stepFilter = (step: CalculatorStep) => {
                    if (instanceOfCalculatorStepWithData(step))
                        return ![
                            'age2',
                            'smokerCd2',
                            'occupationSelect2',
                            'childNum2',
                            'youngestChild2',
                            'income2',
                            'mortgage2',
                            'mortgagePayment2',
                            'mortgageCleared2',
                            'lifeCoverConversion2',
                            'lifeCoverAmt2',
                            'incomeProtection2',
                            'specifiedIllnessAmt2',
                            'specifiedIllnessConversion2',
                        ].includes(step.fieldName)
                    return true
                }
                transformedSteps = transformSteps(steps, stepFilter)
                const textFilter = (step: CalculatorStep) => {
                    return !['Additional Person'].includes(step.label)
                }
                transformedSteps = transformSteps(steps, textFilter)
            }
        }
        return transformedSteps
    }

export const currentPensionTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.currentPension === 'Y') {
            transformedSteps = steps.filter(
                (step) =>
                    step.codename !== 'start_contributing_time___pensions' &&
                    step.codename !==
                        'monthly_salary_contribution___pensions' &&
                    step.codename !== 'income_for_retirement___pensions' &&
                    step.codename !== 'group_3___current_pension___no' &&
                    step.codename !==
                        'employee_contribution_starting_pension__pensions'
            )
        }
        if (data.currentPension === 'N') {
            transformedSteps = steps.filter(
                (step) =>
                    step.codename !== 'current_pension_value___pensions' &&
                    step.codename !== 'employee_contribution___pensions' &&
                    step.codename !== 'employer_contribution___pensions' &&
                    step.codename !== 'group_2___current_pension___yes' &&
                    step.codename !== 'corporate_pension___pensions'
            )
        }

        return transformedSteps
    }

export const employeeSalaryContributionTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (!data.employeeContribution) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return ![
                        'employeeSalaryContribution',
                        'employeePercentageContribution',
                    ].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.employeeContribution === 'percentage') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['employeeSalaryContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.employeeContribution === 'euro') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['employeePercentageContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const employerSalaryContributionTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (!data.employerContribution) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return ![
                        'employerSalaryContribution',
                        'employerPercentageContribution',
                    ].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.employerContribution === 'percentage') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['employerSalaryContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.employerContribution === 'euro') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['employerPercentageContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const corporatePensionTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (!data.corporatePension) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return ![
                        'employerContribution',
                        'employerPercentageContribution',
                        'employerSalaryContribution',
                    ].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.corporatePension !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['employerContribution'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }

        return transformedSteps
    }

export const targetContributionTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (!data.targetContribution) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return ![
                        'targetSalaryContribution',
                        'targetPercentageContribution',
                    ].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.targetContribution === 'percentage') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['targetSalaryContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        if (data.targetContribution === 'euro') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['targetPercentageContribution'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const monthlyIncomeTargetTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (!data.monthlyTargetChoice) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['monthlyPaymentTargetValue'].includes(
                        step.fieldName
                    )
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }
export const lifeTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.lifeCoverConversion1 === 'Y') {
        transformedSteps = steps.filter(
            (step) => step.codename !== 'group_5___one_plan'
        )
    }
    return transformedSteps
}

export const billTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.incomeProtection === 'Y') {
        transformedSteps = steps.filter(
            (step) => step.codename !== 'group_6___one_plan'
        )
    }
    return transformedSteps
}

export const covidTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.covid === 'N') {
        transformedSteps = steps.filter(
            (step) => step.codename !== 'group_6___one_plan'
        )
    }
    return transformedSteps
}

export const funeralTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.maritalStatus1 !== 'N' && data.childNum1 !== 0) {
        transformedSteps = steps.filter(
            (step) => step.codename !== 'group_8___one_plan'
        )
    } else {
        transformedSteps = steps.filter(
            (step) =>
                step.codename !== 'group_5___one_plan' &&
                step.codename !== 'group_7___one_plan'
        )
    }
    return transformedSteps
}

export const billPayout: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.billPayConversion1 !== 'Y') {
        const stepFilter = (step: CalculatorStep) => {
            if (instanceOfCalculatorStepWithData(step))
                return !['billPayDeferredWeeks1'].includes(step.fieldName)
            return true
        }
        transformedSteps = transformSteps(steps, stepFilter)
    }
    return transformedSteps
}

export const SICTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.specifiedIllnessConversion1 === 'Y') {
        transformedSteps = steps.filter(
            (step) => step.codename !== 'group_7___one_plan'
        )
    }
    return transformedSteps
}

export const lifeCoverTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.lifeCoverConversion1 !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['lifeCoverAmt1'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const lifeCover2Transformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.lifeCoverConversion2 !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['lifeCoverAmt2'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const specifiedIllnessTransformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.specifiedIllnessConversion1 !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['specifiedIllnessAmt1'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const specifiedIllness2Transformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.specifiedIllnessConversion2 !== 'Y') {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['specifiedIllnessAmt2'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const childrenTransformation: CalculatorConfig['stepsTransformation'] = (
    steps,
    data
) => {
    let transformedSteps: CalculatorSteps = steps
    if (data.childNum1 === '0' || data.childNum1 === undefined) {
        const stepFilter = (step: CalculatorStep) => {
            if (instanceOfCalculatorStepWithData(step))
                return !['youngestChild1'].includes(step.fieldName)
            return true
        }
        transformedSteps = transformSteps(steps, stepFilter)
    }
    return transformedSteps
}

export const children2Transformation: CalculatorConfig['stepsTransformation'] =
    (steps, data) => {
        let transformedSteps: CalculatorSteps = steps
        if (data.childNum2 === '0' || data.childNum2 === undefined) {
            const stepFilter = (step: CalculatorStep) => {
                if (instanceOfCalculatorStepWithData(step))
                    return !['youngestChild2'].includes(step.fieldName)
                return true
            }
            transformedSteps = transformSteps(steps, stepFilter)
        }
        return transformedSteps
    }

export const analyticsStepChange: CalculatorConfig['onStepChange'] = (
    step,
    steps,
    data
) => {
    const currentStep = steps[step] as CalculatorStepWithData
    const isLastStep = step + 1 === steps.length
    const isFirstStep = step === 1
    const isPersonalDetailsDesktop = step === 2
    const isFinancialDetailsDesktop = step === 3
    const isAdditionalPersonDesktop = step === 4
    const { pathname } = window?.location

    if (isFirstStep) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/personal-details`,
            },
        })
    }

    if (currentStep.fieldName === 'age2') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/personal-details-user-2`,
            },
        })
    }
    if (isPersonalDetailsDesktop && currentStep.fieldName !== 'age1') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/financial-details-desktop`,
            },
        })
    }
    if (currentStep.fieldName === 'jointLife') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/financial-details`,
            },
        })
    }
    if (currentStep.fieldName === 'smokerCd2') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/financial-details-user-2`,
            },
        })
    }
    if (
        isFinancialDetailsDesktop &&
        currentStep.fieldName !== 'maritalStatus1'
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-protection-desktop`,
            },
        })
    }
    if (currentStep.fieldName === 'mortgagePayment') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-protection`,
            },
        })
    }
    if (currentStep.fieldName === 'lifeCoverConversion2') {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/current-protection-user-2`,
            },
        })
    }

    if (
        isAdditionalPersonDesktop &&
        currentStep.fieldName !== 'occupationSelect1'
    ) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/additional-person-desktop`,
            },
        })
    }
    if (isLastStep) {
        TagManager.dataLayer({
            dataLayer: {
                event: 'pageview',
                pageTitle: pathname,
                virtualURL: `${pathname}/quote-results`,
            },
        })
    }
}

export const consultationStepChange: CalculatorConfig['onStepChange'] = (
    step,
    steps,
    data,
    source
) => {
    if (data.consultationAffirmed === 'Y' && data.userName) {
        let ticket = {
            requesterName: data.userName,
            type: 'incident',
            subject: `Requested consultation from ${data.userName} for ${source}`,
            tags: ['contact-us'],
            commentText: `Requested call from ${data.userName} to number: +${data.phoneNumber}
                for ${source} from new ${source} calculator`,
            phoneNumber: data.phoneNumber,
            timeToCall: 'n/a',
        } as ZenDeskAnonymousTicket
        createAnonymousTicket(ticket)
    }
}

export const getApiConstants: any = (
    quickQuoteId: string,
    productId: number
) => {
    return {
        quickQuoteId: quickQuoteId,
        productId: productId,
        coverTypeCd: 'L',
        indexation: 'FALSE',
        frequencyCd: 'M',
        conversion: 'FALSE',
        dateOfBirth1Day: new Date().getDate(),
        dateOfBirth1Month: new Date().getMonth() + 1,
        dateOfBirth2Day: new Date().getDate(),
        dateOfBirth2Month: new Date().getMonth() + 1,
    }
}

export const configureCalculatorQuoteData = (type: string) => {
    return async (quoteData: any) => {
        let apiConstants = {}

        switch (type) {
            case 'mortgage-protection':
                apiConstants = getApiConstants('lifeMortgage', 38)
                break
            case 'life-long-protection':
                apiConstants = getApiConstants('lifeLong', 23)
                break
            case 'one-plan':
                apiConstants = getApiConstants('lifeOnePlan', 104)
                break
            case 'term-life-protection':
                apiConstants = getApiConstants('lifeTermSum', 19)
                break
            case 'pensions':
                apiConstants = getApiConstants('lifeTermSum', '19')
                break
            default:
                break
        }

        // apps.qairishlife.ie
        const apiUrl = `${API_URL}/myonlineservices/servlet/LifeCoverQuote/`

        /*
            there needs to be 3 API requests performed to get the 3 plans, standard, essential and comprehensive
            each request passes diffrent params for SIC, Life and Bill cover.
        */
        // if (type === 'one-plan') {
        //     const onePlanParamsStandard = getOnePlanParams(
        //         quoteData,
        //         'standard',
        //         occupations
        //     )
        //     const onePlanParamsEssential = getOnePlanParams(
        //         quoteData,
        //         'essential',
        //         occupations
        //     )
        //     const onePlanParamsComprehensive = getOnePlanParams(
        //         quoteData,
        //         'comprehensive',
        //         occupations
        //     )

        //     const paramsStandard = qs.stringify({
        //         ...apiConstants,
        //         ...onePlanParamsStandard,
        //     })
        //     const paramsEssential = qs.stringify({
        //         ...apiConstants,
        //         ...onePlanParamsEssential,
        //     })
        //     const paramsComprehensive = qs.stringify({
        //         ...apiConstants,
        //         ...onePlanParamsComprehensive,
        //     })
        //     const [standard, essential, comprehensive] = await Promise.all([
        //         axios.get(`${apiUrl}?${paramsStandard}`),
        //         axios.get(`${apiUrl}?${paramsEssential}`),
        //         axios.get(`${apiUrl}?${paramsComprehensive}`),
        //     ])

        //     let standardQuoteData
        //     let essentialQuoteData
        //     let comprehensiveQuoteData
        //     let data
        //     if (
        //         standard.data === 'Error' ||
        //         essential.data === 'Error' ||
        //         comprehensive.data === 'Error'
        //     ) {
        //         data = { error: true }
        //     } else {
        //         standardQuoteData = qs.parse(
        //             standard.data
        //                 .replace('var result = ', '')
        //                 .replace(';', '')
        //                 .replaceAll("'", '')
        //         )
        //         essentialQuoteData = qs.parse(
        //             essential.data
        //                 .replace('var result = ', '')
        //                 .replace(';', '')
        //                 .replaceAll("'", '')
        //         )
        //         comprehensiveQuoteData = qs.parse(
        //             comprehensive.data
        //                 .replace('var result = ', '')
        //                 .replace(';', '')
        //                 .replaceAll("'", '')
        //         )
        //         data = {
        //             standardQuoteData,
        //             essentialQuoteData,
        //             comprehensiveQuoteData,
        //             error: false,
        //         }
        //     }
        //     return data
        // } else {
        const params = qs.stringify({
            ...apiConstants,
            ...quoteData,
            dateOfBirth1Year: new Date().getFullYear() - quoteData.age1,
            dateOfBirth2Year: new Date().getFullYear() - quoteData.age2,
        })

        const { data } = await axios.get(`${apiUrl}?${params}`)

        return qs.parse(data.replace('var result = ', '').replace(/'/, ''))
        // }
    }
}
