import { CalculatorConfig } from 'common/components/templates/Calculator/config/index'
import { UMBRELLA_CIRCLE } from 'common/components/design-system/assets/icons'
import {
    jointApplicationTransformation,
    consultationTransformation,
    configureCalculatorQuoteData,
} from './configFunctions'
import { LifeQuote } from './LifeQuote'
import { createCrmLead } from 'common/services/crm/createCrmLead'

export const termLifeProtectionCopy: CalculatorConfig = {
    getQuoteData: configureCalculatorQuoteData('term-life-protection'),

    icon: UMBRELLA_CIRCLE,
    quoteComponent: LifeQuote as CalculatorConfig['quoteComponent'],
    stepsTransformation: (steps, data) => {
        const firstSteps = consultationTransformation(steps, data)
        return jointApplicationTransformation(firstSteps, data)
    },
    createCrmLead: (data) => createCrmLead(data, 'Term Life Protection'),
}
