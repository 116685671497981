import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import React from 'react'
import { theme } from '../../theme'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface StepperDotsProps {
    classes: any
    total: number
    current: number
}
const { palette } = theme
const styles: Styles<Theme, {}, string> = {
    root: {
        dot: {
            backgroundColor: 'yellow'
        }
    },
    current: {
        color: palette.NEUTRAL_900
    },
    dots: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    dot: {
        backgroundColor: palette.NEUTRAL_200,
        borderRadius: '50%',
        width: 8,
        height: 8,
        margin: '0 2px'
    },
    dotActive: {
        backgroundColor: '#fff',
        border: `3px solid ${palette.VIBRANT_500_PRIMARY}`
    }
}
export const StepperDotsElem: React.FC<StepperDotsProps> = ({ ...props }) => {
    const { current, total, classes } = props
    return (
        <>
            <div className={classes.dots}>
                {[...new Array(total)].map((_, index) => (
                    <div
                        key={index}
                        className={clsx(classes.dot, {
                            [classes.dotActive]: index + 1 === current
                        })}
                    />
                ))}
            </div>
        </>
    )
}
export const StepperDots = withStyles(styles)(StepperDotsElem)
