import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { theme } from '../../theme'
import { InputField } from '../inputField'
import { IRELAND, UK } from './constants'
import { matchCountry } from './utils'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PhoneInputProps {
    classes: any
    isOptional?: boolean
    isDisabled?: boolean
    helper?: string
    label?: string
    value?: string
    placeholder?: string
    isValid?: boolean
    onBlur?: (val: string | number) => void
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        fontSize: '16px',
        letterSpacing: '0.32px',
        lineHeight: '20px',
        fontFamily: 'Assistant',
        color: palette.NEUTRAL_700,
        fontWeight: 700,
        paddingBottom: '4px'
    }
}

export const matchPrefix = (value: any) => {
    let phoneNumber
    if (value) {
        phoneNumber = matchCountry(value, IRELAND)
        if (phoneNumber) return phoneNumber
        phoneNumber = matchCountry(value, UK)
    }
    if (phoneNumber) return phoneNumber
    return { countryCode: null, country: null, type: null, prefix: null, value }
}

export const PhoneInputField: React.FC<PhoneInputProps> = ({ ...props }) => {
    const {
        onBlur,
        isOptional,
        isDisabled,
        helper,
        label,
        value,
        placeholder,
        isValid
    } = props
    const [background, setBackground] = useState<string>('')
    const [phoneValue, setPhoneValue] = useState(value)
    useEffect(() => setPhoneValue(value), [value])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => checkNumber(phoneValue), [value])

    const setValue = (phoneNumber: any) => {
        setIcon(phoneNumber)
        formatNumber(phoneNumber)
    }

    const setIcon = ({
        country,
        type,
        prefix,
        phoneNumber
    }: {
        country: string
        type: string
        prefix: string
        phoneNumber: string
    }) => {
        if (country === IRELAND) {
            setBackground('flagIreland')
        } else if (country === UK) {
            setBackground('flagUK')
        } else {
            setBackground('')
        }
    }

    const formatNumber = ({
        countryCode,
        country,
        type,
        prefix,
        phoneNumber
    }: {
        countryCode: string
        country: string
        type: string
        prefix: string
        phoneNumber: string
    }) => {
        if (phoneNumber === undefined || !phoneNumber) {
            setPhoneValue(undefined)
        } else {
            const formattedNumber = `${countryCode} ${prefix} ${phoneNumber}`
            setPhoneValue(formattedNumber)
        }
    }

    const checkNumber = (value: any) => {
        const phoneNumber = matchPrefix(value)
        if (phoneNumber !== undefined) setValue(phoneNumber)
        value && value !== '' && value !== undefined && onBlur && onBlur(value)
    }

    const validateInput = (event: any) => {
        const validInputs = [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '+',
            'Shift',
            'Backspace',
            'ArrowLeft',
            'ArrowRight',
            'ArrowUp',
            'ArrowDown'
        ]
        const isValidInput =
            validInputs.filter(value => event.key === value).length > 0
        if (!isValidInput) event.preventDefault()
    }

    return (
        <InputField
            name='tel'
            type='tel'
            onKeyDown={validateInput}
            formattedPhone={phoneValue}
            background={background}
            onBlur={checkNumber}
            isOptional={isOptional}
            isDisabled={isDisabled}
            helper={helper}
            label={label}
            value={phoneValue}
            placeholder={placeholder}
            isValid={isValid}
        />
    )
}

export const PhoneInput = withStyles(styles)(PhoneInputField)
