import { Box, BoxProps, Typography, TypographyProps } from "@material-ui/core"
import React from "react"

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TextProps extends TypographyProps {
  box?: BoxProps
}

export const Text: React.FC<TextProps> = ({ box, ...props }) => {
  return (
    <Box {...box}>
      <Typography {...props} />
    </Box>
  )
}
