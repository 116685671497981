import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React from 'react'
import { theme } from '../../theme'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SmallTitleProps {
    classes: any
    text: string
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        fontFamily: '"Assistant", sans-serif',
        fontWeight: 'normal',
        fontSize: '28px',
        lineHeight: '120%',
        color: palette.NEUTRAL_900,
        margin: '7px 0px 40px 0px',
        maxWidth: '95%'
    }
}

export const SmallTitleElem: React.FC<SmallTitleProps> = ({ ...props }) => {
    const { classes, text } = props
    return <p className={classes.root}>{text}</p>
}

export const SmallTitle = withStyles(styles)(SmallTitleElem)
