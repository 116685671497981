import { Theme, withStyles } from '@material-ui/core/styles'
import { Styles } from '@material-ui/core/styles/withStyles'
import React, { useEffect, useState } from 'react'
import { theme } from '../../theme'
import CANCEL_SMALL from './assets/cancel-small.svg'
import CURRENCY_EURO_MEDIUM from './assets/currency-euro-medium.svg'
import FLAG_IRELAND_MEDIUM from './assets/flag-ireland-medium.svg'
import FLAG_UK_MEDIUM from './assets/flag-uk-medium.svg'
import TICK_SMALL_GREEN from './assets/tick-small-green.svg'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputProps {
    classes: any
    placeholder?: string
    value?: string | number
    onchange?: (val: string | number) => void
    isValid?: boolean
    isDisabled?: boolean
    helper?: string
    isWhiteBackground?: boolean
    type?: string
    label?: string
    background?: string
    short?: boolean
    onBlur?: (val: string | number) => void
    onKeyDown?: (val: string | number) => void
    formattedPhone?: string
    name?: string
    min?: number
    max?: number
}

const { palette } = theme

const styles: Styles<Theme, {}, string> = {
    root: {
        backgroundColor: palette.BRAND_100,
        borderRadius: '4px',
        width: '90%',
        maxWidth: '292px ',
        height: '18px',
        border: '2px solid transparent',
        padding: '15px 14px',
        fontSize: '16px',
        letterSpacing: '0.32px',
        lineHeight: '20px',
        fontFamily: 'Assistant',
        color: palette.BRAND_900,
        fontWeight: 400,
        marginTop: '4px',

        '&:hover': {
            borderColor: palette.BRAND_200,
        },

        '&:focus': {
            borderColor: '#3681D9',
        },

        '&::placeholder': {
            color: palette.NEUTRAL_300,
        },
    },
    white: {
        backgroundColor: `${palette.WHITE} !important`,
    },
    error: {
        backgroundColor: palette.WHITE,
        borderColor: '#D9145B',
        backgroundImage: `url(${CANCEL_SMALL})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 14px top 14px',
    },
    validated: {
        backgroundImage: `url(${TICK_SMALL_GREEN})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 14px top 14px',
    },
    disabled: {
        backgroundColor: palette.BRAND_100,
        color: palette.NEUTRAL_200,
        cursor: 'not-allowed',
    },
    helper: {
        color: palette.NEUTRAL_500,
        fontSize: '14px',
        lineHeight: '16px',
        marginTop: '4px',
        fontFamily: 'Assistant',
        letterSpacing: '0.28px',
        textAlign: 'left',
    },
    helperError: {
        color: '#D9145B',
    },
    short: {
        width: '100px',
    },
    withIcon: {
        paddingLeft: '56px',
        width: '250px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left 16px center',
    },
}

export const getIcon = (icon: any, isValid: boolean) => {
    const backgroundIcon = getBackgroundIcon(icon)
    if (isValid === true) {
        return `url(${backgroundIcon}), url(${TICK_SMALL_GREEN})`
    } else if (isValid === false) {
        // return `url(${backgroundIcon}), url(${CANCEL_SMALL})`
        return `url(${backgroundIcon}))`
    }
    return `url(${backgroundIcon})`
}

export const getBackgroundIcon = (icon: any) => {
    switch (icon) {
        case 'flagIreland':
            return FLAG_IRELAND_MEDIUM
        case 'flagUK':
            return FLAG_UK_MEDIUM
        case 'euro':
            return CURRENCY_EURO_MEDIUM
        default:
            return null
    }
}

export const getBackgroundPosition = (isValid: boolean) => {
    const prefixBackgroundPosition = 'left 16px center'
    if (isValid === true || isValid === false) {
        return `${prefixBackgroundPosition}, right 14px top 14px`
    }
    return prefixBackgroundPosition
}

export const InputField: React.FC<InputProps> = ({ ...props }) => {
    const {
        formattedPhone,
        classes,
        placeholder,
        value,
        onchange,
        isValid,
        isDisabled,
        helper,
        isWhiteBackground,
        type,
        label,
        background,
        short,
        onBlur,
        onKeyDown,
        name,
        min,
        max,
    } = props
    const [inputValue, setValue] = useState(value)
    const [formattedValue, setFormattedValue] = useState(formattedPhone)
    useEffect(() => setValue(value), [value])
    useEffect(() => setFormattedValue(formattedPhone), [formattedPhone])

    const handleChange = (val: string) => {
        setFormattedValue(undefined)
        setValue(val)
        onchange && onchange(val)
    }

    const handleKeyDown = (val: any) => onKeyDown && onKeyDown(val)

    const handleBlur = (val: any) => {
        let validValue = val
        if (min && max) {
            validValue = Math.max(Math.min(val, max), min)
            handleChange(validValue)
        }
        onBlur && onBlur(val)
    }

    const icon = getIcon(background, !!isValid)
    const backgroundPosition = getBackgroundPosition(!!isValid)

    const val = formattedValue ? formattedValue : inputValue
    return (
        <>
            <input
                onWheel={(e) => {
                    const target = e.target as HTMLInputElement
                    target.type = 'text'
                    setTimeout(() => {
                        target.type = 'number'
                    }, 0)
                }}
                className={`${classes.root}
          ${isWhiteBackground && classes.white}
          ${isValid && classes.validated}
          ${isValid === false && classes.error}
          ${isDisabled && classes.disabled}
          ${short && classes.short}
          ${background && background !== undefined && classes.withIcon}
        `}
                autoComplete='on'
                placeholder={placeholder}
                value={val}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={(e) => handleBlur(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
                disabled={isDisabled}
                type={type}
                aria-label={label}
                style={
                    icon
                        ? { backgroundImage: icon, backgroundPosition }
                        : undefined
                }
                name={name}
                id={name}
                min='0'
                max={max}
            />
            {helper && helper !== '' && (
                <caption className={classes.helper}>{helper}</caption>
            )}
        </>
    )
}

export const Input = withStyles(styles)(InputField)
