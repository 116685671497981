import { Box } from '@material-ui/core'
import { SmallTitle } from 'common/components/design-system/atoms/SmallTitle'
import { Text } from 'common/components/molecules/Text'
import React from 'react'
import { Tooltip } from '../../../Tooltip'
import { CalculatorStepDropdown as CalculatorStepDropdownInterface } from '../../interfaces'
import { occupations } from './occupations'
import { useDesktop } from 'common/hooks/useDesktop'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        input: {
            backgroundColor: '#F1F3FD',
            '&:hover': {
                backgroundColor: '#F1F3FD',
            },
            '&$focused': {
                backgroundColor: '#F1F3FD',
            },
            disableUnderline: true,
            paddingLeft: '18px',
        },
        parent: {
            height: '20px',
            maxWidth: '320px',
        },
        margin: {
            margin: theme.spacing(1),
        },
    })
)
export interface CalculatorStepDropdownProps
    extends CalculatorStepDropdownInterface {
    updateData: (newData: { [key: string]: any }) => void
}

//updateData function that puts a new value into the calculator
//when components mounts
export const CalculatorStepDropdown: React.FC<CalculatorStepDropdownProps> = ({
    fieldName,
    group,
    title,
    tooltip,
    text,
    options,
    updateData,
    value,
}) => {
    const classes = useStyles()

    const update = (value: string) => {
        updateData({ [fieldName]: value })
    }

    const desktop = useDesktop()

    return (
        <>
            {group && (
                <Box>
                    {tooltip && (
                        <Tooltip
                            title={tooltip.title}
                            content={tooltip.content}
                            alignRight={true}
                        />
                    )}
                    {title && <SmallTitle text={title} />}
                </Box>
            )}

            <Box
                style={{
                    marginTop: desktop ? '0px' : '20px',
                }}
            >
                <Autocomplete
                    options={
                        fieldName.includes('occupationSelect')
                            ? occupations
                            : options
                            ? options
                            : occupations
                    }
                    className={classes.parent}
                    onChange={(event, newValue) => {
                        update(newValue?.value || '0')
                    }}
                    value={
                        value as unknown as {
                            value: string
                            label: string
                            BillPayOccupationClass: string
                        }
                    }
                    renderInput={(params) => (
                        <TextField
                            autoComplete='true'
                            {...params}
                            className={classes.input}
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                            }}
                            id='bootstrap-input'
                        />
                    )}
                    getOptionLabel={(option) => option.label}
                />
            </Box>
            <Box style={{ marginTop: '24px' }}>
                {text && <Text label={text} />}
            </Box>
        </>
    )
}
