import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Text } from 'common/components/molecules/Text'
import { makeStyles } from '@material-ui/core/styles'
import { useDesktop } from 'common/hooks/useDesktop'

export interface LegendProps {
    label: string
    value: string
    color: string
}

export const Legend: React.FC<LegendProps> = ({ label, color, value }) => {
    const useStyles = makeStyles({
        legendLabel: {
            fontWeight: 700,
            fontSize: '12px',
            color: '#4E4670',
            textTransform: 'uppercase'
        },
        legendValue: {
            fontWeight: 700,
            fontSize: '24px',
            color: '#4E4670'
        }
    })
    const desktop = useDesktop()

    const classes = useStyles()

    return (
        <>
            <Grid item xs={12} md={4}>
                <Grid container style={{ marginTop: '15px' }}>
                    <Grid
                        item
                        xs={12}
                        md={12}
                        style={{
                            display: 'flex',
                            justifyContent: desktop ? 'center' : 'flex-start'
                            //margin: desktop ? '0px' : '20px 0px 10px 0px'
                        }}
                    >
                        <Box
                            style={{
                                height: '17px',
                                width: '17px',
                                background: `${color}`,
                                marginRight: '10px'
                            }}
                        ></Box>
                        <Box>
                            <Text
                                html={true}
                                label={label}
                                className={classes.legendLabel}
                            />
                            <Text
                                html={true}
                                label={value}
                                className={classes.legendValue}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>{' '}
        </>
    )
}
